import React, { useEffect, useState } from 'react';
import { useConfig } from '../../../Context/ConfigContext';
import { createItem } from '../../../database/firebase';
import {
  MenuItem,
  Paper,
  Select,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Button, FormControl, Box, OutlinedInput } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  RFPercentage,
  modalidades,
  responsiveWidth,
} from '../../../utils/Functions';
import Texto from '../../Componentes/Texto';
import { CuadroDeTexto } from '../../Componentes/CuadroDeTexto';
import { useData } from '../../../Context/DataContext';
import moment from 'moment';
import { DateCalendar } from '@mui/x-date-pickers';

const CrearArbitro = () => {
  const { activarAlerta } = useConfig();
  const { ligas } = useData();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const initialValues = {
    nombre: '',
    modalidades: [],
    nombreUsuario: '',
    telefono: '',
    correo: '',
    ligasHabilitadas: [],
    diasNoDisponible: [],
    activo: true,
  };

  const validationSchema = Yup.object({
    nombre: Yup.string().required('El nombre es obligatorio'),
  });

  const [visible, setVisible] = useState(false);

  const crearArbitro = async (values) => {
    await createItem('arbitros', values);

    activarAlerta({
      estado: true,
      status: 'success',
      titulo: 'Liga creada correctamente',
      descripcion: '',
    });
  };

  // const guardarLiga = async () => {
  //   await updateItem('ligas', props.params.liga, {
  //     nombre: nombre,
  //     tipo:
  //       tipo === 0
  //         ? 'Oficial'
  //         : tipo === 1
  //         ? 'Categorías Menores'
  //         : tipo === 2
  //         ? 'Aficionados'
  //         : 'Liga de Invierno',
  //     organizadores: organizadores,
  //     resultadoFinal: resultadosPosibles[resultadoFinal],
  //     cromos: sobres[index],
  //     apuestas: apuestas,
  //     colores: colores,
  //   });

  //   if (partidos.length > 0) {
  //     partidos.forEach(async (partido) => {
  //       await createItem('partidos', {
  //         local: partido.local,
  //         visitante: partido.visitante,
  //         jornada:
  //           partido.jornada !== undefined ? parseInt(partido.jornada) : 1,
  //         fecha: partido.fecha !== undefined ? partido.fecha : '',
  //         hora: partido.hora !== undefined ? partido.hora : '',
  //         estado: 'Sin jugar',
  //         bolera: boleraDefecto
  //           ? boleraDefecto.id
  //           : buscarPeña(partido.local).bolera,
  //         televisiones: [],
  //         foro: [],
  //         log: [],
  //         chicos: [],
  //         tipo: 'liga',
  //         liga: props.params.liga,
  //         ch_l: 0,
  //         ch_v: 0,
  //         apuestas1: 0,
  //         apuestas2: 0,
  //         apuestasX: 0,
  //         segundoPartido: false,
  //         apuestas: apuestas,
  //       });
  //     });
  //   }

  //   activarAlerta({
  //     estado: true,
  //     status: 'success',
  //     titulo: 'Guardando correctamente',
  //     descripcion: '',
  //   });
  // };

  const scrapp = async () => {
    try {
      fetch('https://maderadeser.com/comunicacion/circulares/circular/2718-2/')
        .then((response) => response.text())
        .then((html) => {
          // Creamos un DOMParser para parsear el HTML recibido
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');

          // Usamos el DOM para seleccionar los elementos del artículo
          const article = doc.querySelector('#post-28420');
          const title = article ? article.querySelector('h1')?.innerText : null;
          const subtitle = article
            ? article.querySelector('h2')?.innerText
            : null;
          const description = article
            ? article.querySelector('p')?.innerText
            : null;
          const documentUrl = article
            ? article.querySelector('a[href$=".pdf"]')?.href
            : null;

          // Establecemos los datos en el estado
          console.log({
            title,
            subtitle,
            description,
            documentUrl,
          });
        })
        .catch((error) => console.log('Error al obtener los datos', error));
    } catch (error) {}
  };

  useEffect(() => {
    scrapp();
  }, []);

  return (
    <Paper style={{ flex: 1, height: '96%' }}>
      <Box
        flex={1}
        padding={2}
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Texto
          style={{
            fontSize: RFPercentage(3),
            marginBottom: 12,
            fontWeight: '600',
          }}
        >
          Crear Árbitro
        </Texto>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={crearArbitro}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                padding={2}
                width={isMobile ? responsiveWidth(90) : responsiveWidth(40)}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={'100%'}
                  marginBottom={'24px'}
                >
                  <CuadroDeTexto
                    name='nombre'
                    label='Nombre'
                    value={values.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.nombre && Boolean(errors.nombre)}
                    helperText={touched.nombre && errors.nombre}
                    debounceable
                    style={{ marginRight: 12 }}
                  />
                  <CuadroDeTexto
                    name='nombreUsuario'
                    label='Nombre de usuario'
                    value={values.nombreUsuario}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.nombreUsuario && Boolean(errors.nombreUsuario)
                    }
                    helperText={touched.nombreUsuario && errors.nombreUsuario}
                    debounceable
                    style={{ marginLeft: 12 }}
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  <CuadroDeTexto
                    name='telefono'
                    label='Telefono'
                    value={values.telefono}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.telefono && Boolean(errors.telefono)}
                    helperText={touched.telefono && errors.telefono}
                    debounceable
                    style={{ marginRight: 12 }}
                  />
                  <CuadroDeTexto
                    name='correo'
                    label='Correo electrónico'
                    value={values.correo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.correo && Boolean(errors.correo)}
                    helperText={touched.correo && errors.correo}
                    debounceable
                    style={{ marginLeft: 12 }}
                  />
                </Box>
                <Box
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <FormControl margin='normal' style={{ width: '100%' }}>
                    <Texto
                      style={{ fontWeight: '300', fontSize: RFPercentage(1.2) }}
                    >
                      Modalidades
                    </Texto>
                    <Select
                      labelId='Modalidad'
                      id='Modalidad'
                      value={values.modalidades}
                      style={{ width: '100%' }}
                      multiple
                      input={<OutlinedInput label='Modalidades' />}
                      onChange={(event) =>
                        setFieldValue('modalidades', event.target.value)
                      }
                    >
                      {modalidades.map((i) => (
                        <MenuItem style={{ width: '100%' }} value={i.texto}>
                          {i.texto}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <FormControl margin='normal' style={{ width: '100%' }}>
                    <Texto
                      style={{ fontWeight: '300', fontSize: RFPercentage(1.2) }}
                    >
                      Ligas
                    </Texto>
                    <Select
                      labelId='Ligas'
                      id='Ligas'
                      value={values.ligasHabilitadas}
                      style={{ width: '100%' }}
                      multiple
                      input={<OutlinedInput label='Ligas' />}
                      disabled={values.modalidades.length === 0}
                      onChange={(event) =>
                        setFieldValue('ligasHabilitadas', event.target.value)
                      }
                    >
                      {ligas
                        .filter(
                          (i) =>
                            values.modalidades.includes(i.modalidad) &&
                            i.año === moment().year()
                        )
                        .map((i) => (
                          <MenuItem style={{ width: '100%' }} value={i.id}>
                            {i.nombre}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <DateCalendar />

                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  margin='normal'
                  style={{ marginTop: 24 }}
                  fullWidth
                >
                  Crear
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};

export default CrearArbitro;
