import { Fade } from '@material-ui/core';
import { useState } from 'react';
import VizSensor from 'react-visibility-sensor'; // or use any other 3rd party plugin or define your own

const ScrollRevealComponent = ({ children }) => {
  let [active, setActive] = useState(false);

  return (
    <VizSensor
      partialVisibility={true}
      offset={{ bottom: window.innerHeight / 8, top: window.innerHeight / 8 }}
      onChange={(isVisible) => {
        setActive(isVisible);
      }}
    >
      <Fade in={active} timeout={1000}>
        {children}
      </Fade>
    </VizSensor>
  );
};

export default ScrollRevealComponent;
