import React, { useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { WebScreen } from './WebScreen.js';
import { Aplicacion } from './Aplicacion.js';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../Router/routes.js';
import { useTema } from '../../Context/TemaContext.js';
import { Header } from './Header';
import LigasMedios from './Medios/LigasMedios.js';
import Medios from './Medios/Medios.js';
import Tienda from './Tienda.js';
import TV from './TV/TV.js';
import { useConfig } from '../../Context/ConfigContext.js';
import Ligas from './Competiciones/Ligas/Ligas.js';
import Copas from './Competiciones/Copas/Copas.js';
import Concursos from './Competiciones/Concursos/Concurso.js';
import NotFound from './NotFound.js';
import ModalidadesInfo from './Competiciones/ModalidadesInfo.js';
import { MapaBoleras } from './Boleras/MapaBoleras.js';
import Admin from './Admin/Admin.js';
import Grid from '@mui/material/Grid2';
import { useUsuario } from '../../Context/UsuarioContext.js';
import Juego from './Juego/Juego.js';
import Premium from './Premium/Premium.js';

const Anuncio = () => {
  const location = useLocation();

  useEffect(() => {
    const adContainer = document.getElementById('ad-container');
    if (adContainer) {
      const scriptElement = document.createElement('script');
      scriptElement.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5968796742183376';
      scriptElement.async = true;
      adContainer.appendChild(scriptElement);

      scriptElement.onload = () => {
        try {
          if (window.adsbygoogle) {
            window.adsbygoogle.push({});
          }
        } catch (error) {
          console.error('Error loading ads:', error);
        }
      };

      return () => {
        adContainer.removeChild(scriptElement);
      };
    }
  }, [location?.pathname]);

  return (
    <Box
      id='anuncio'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <div
        id='ad-container'
        style={{ width: '100%', maxHeight: '100px', overflow: 'hidden' }}
      >
        <ins
          className='adsbygoogle'
          style={{
            display: 'block',
            width: '100%',
            height: 'auto',
          }}
          data-ad-client='ca-pub-5968796742183376'
          data-ad-slot='7309751360'
          // data-ad-format='auto'
          // data-full-width-responsive='true'
        ></ins>
      </div>
    </Box>
  );
};

export const Main = () => {
  const { tema } = useTema();
  const { setModalVisible } = useConfig();
  const { premium } = useUsuario();

  const location = useLocation();

  const [visibleUsuario, setVisibleUsuario] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  function redirectToMobileApp(url) {
    let didRedirect = false;

    window.location.href = url;

    setTimeout(() => {
      if (document.visibilityState === 'hidden') {
        didRedirect = true;
      } else {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);

        setTimeout(() => {
          if (!didRedirect) {
            setModalVisible({
              children: <Tienda url={url} />,
            });
          }
        }, 2000);
      }
    }, 1500);
  }

  useEffect(() => {
    const shouldRedirect =
      location.pathname.includes('tutorial') ||
      location.pathname.includes('partido') ||
      location.pathname.includes('concurso') ||
      location.pathname.includes('qrLogin');

    if (shouldRedirect) {
      redirectToMobileApp(`resultados-de-bolos:/${location.pathname}`);
    }
  }, [location]);

  const sinPubli = useMemo(() => {
    return (
      ROUTES.home === location.pathname ||
      ROUTES.modalidades === location.pathname ||
      ROUTES.juego === location.pathname ||
      window.innerWidth <= 900
    );
  }, [location]);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.style.height = '100vh !important';
    }
  }, [location]);

  return (
    <Box
      id={'MainScreen'}
      className='root'
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: sinPubli ? tema.backgroundColor : tema.background,
        transition: 'background-color 0.5s ease, color 1s ease',
        display: 'flex',
        flexDirection: 'column',
        // ...(!sinPubli && { overflow: 'hidden' }),
      }}
    >
      {!(
        location &&
        location.pathname &&
        location.pathname === ROUTES.juego
      ) && (
        <Header
          visible={visible}
          setVisible={setVisible}
          visibleUsuario={visibleUsuario}
          setVisibleUsuario={setVisibleUsuario}
        />
      )}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          backgroundColor: sinPubli ? tema.backgroundColor : tema.background,
        }}
      >
        <Grid
          container
          sx={{
            height: '100%',
          }}
        >
          {!sinPubli && (
            <Grid item size={{ xs: 0, md: 0, lg: 2 }}>
              {premium === false && <Anuncio />}
            </Grid>
          )}
          <Grid item size={{ xs: 12, md: 12, lg: sinPubli ? 12 : 8 }}>
            <Box
              sx={{
                flex: 1,
                height: '100%',
              }}
            >
              <Routes>
                <Route path={ROUTES.home} element={<WebScreen />} />
                <Route path={ROUTES.inicio} element={<Aplicacion />} />
                <Route path={ROUTES.tv} element={<TV />} />
                <Route path={ROUTES.admin} element={<Admin />} />
                <Route path={ROUTES.medios} element={<Medios />} />
                <Route path={ROUTES.ligasMedios} element={<LigasMedios />} />
                <Route path={ROUTES.ligas} element={<Ligas />} />
                <Route path={ROUTES.copas} element={<Copas />} />
                <Route path={ROUTES.concursos} element={<Concursos />} />
                <Route path={ROUTES.campeonatos} element={<Concursos />} />
                <Route
                  path={ROUTES.modalidades}
                  element={<ModalidadesInfo />}
                />
                <Route path={ROUTES.boleras} element={<MapaBoleras />} />
                <Route path={ROUTES.juego} element={<Juego />} />
                <Route path={ROUTES.error} element={<NotFound />} />
                <Route path={ROUTES.premium} element={<Premium />} />
                <Route path={'/'} element={<Navigate to={ROUTES.home} />} />
              </Routes>
            </Box>
          </Grid>
          {!sinPubli && (
            <Grid item size={{ xs: 0, md: 0, lg: 2 }}>
              {premium === false && <Anuncio />}
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
