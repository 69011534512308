import React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Box, ButtonBase, Menu, MenuItem } from '@material-ui/core';
import { useUsuario } from '../../Context/UsuarioContext.js';
import { useTema } from '../../Context/TemaContext.js';
import { Button } from '@mui/material';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import AvatarUsuario from '../Componentes/AvatarUsuario.js';
import { Logo } from '../Componentes/Logo.js';
import { useState } from 'react';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { DrawerUsuario } from './DrawerUsuario.js';
import ModalComponent from './ModalComponent.js';
import { RFPercentage } from '../../utils/Functions.js';
import { DrawerInicio } from './DrawerInicio.js';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

export const Header = ({
  visibleUsuario,
  setVisibleUsuario,
  visible,
  setVisible,
}) => {
  const { usuario } = useUsuario();
  const { tema, cambiarTema } = useTema();

  const handleThemeChange = () => {
    cambiarTema(tema.id === 'Claro' ? 'Oscuro' : 'Claro');
  };

  const handleMenu = () => {
    setVisibleUsuario(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const url = (os) => {
    const url =
      os === 'ios'
        ? 'https://apps.apple.com/es/app/resultados-de-bolos/id1610548244'
        : `https://play.google.com/store/apps/details?id=com.saro.marcos&hl=es_PY&gl=US`;
    window.open(url, '_blank');
  };

  return (
    <Box
      style={{
        backgroundColor: tema.backgroundColor,
        transition: 'background-color 0.5s ease, color 1s ease',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        boxShadow: `0 2px 2px ${
          tema.id === 'Claro'
            ? 'rgba(0, 0, 0, 0.2)'
            : 'rgba(255, 255, 255, 0.1)'
        }`,
        zIndex: 100000,
      }}
    >
      <Box
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Logo openDrawer={() => setVisible(true)} />
        <div>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            size='small'
            variant='contained'
            style={{
              fontSize: RFPercentage(1.4),
              fontWeight: '600',
              borderRadius: 60,
              backgroundColor: tema.colors[500],
              marginLeft: 6,
            }}
          >
            {window.innerWidth < 600 ? '' : 'Descargar'}
            <InstallMobileIcon
              sx={{ marginLeft: window.innerWidth < 600 ? 0 : 2 }}
            />
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              elevation: 0,
              style: {
                overflow: 'visible',
                marginTop: 40,
              },
            }}
          >
            <MenuItem
              onClick={() => url('ios')}
              style={{ fontSize: 26, fontWeight: '500' }}
            >
              <AppleIcon style={{ marginRight: 12 }} />
              App Store
            </MenuItem>
            <MenuItem
              style={{ fontSize: 26, fontWeight: '500' }}
              onClick={() => url('android')}
            >
              <AndroidIcon style={{ marginRight: 12 }} />
              Google Play
            </MenuItem>
          </Menu>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonBase
            onClick={handleThemeChange}
            style={{ marginRight: 12, borderRadius: 60 }}
          >
            {tema.id === 'Claro' ? (
              <LightModeIcon sx={{ color: tema.colors[400] }} />
            ) : (
              <DarkModeIcon sx={{ color: tema.colors[400] }} />
            )}
          </ButtonBase>
          <ButtonBase onClick={handleMenu} style={{ marginRight: 12 }}>
            {usuario && usuario.nombreUsuario !== 'Anónimo' ? (
              <AvatarUsuario usuario={usuario} />
            ) : (
              <AccountCircle sx={{ color: tema.colors[400] }} />
            )}
          </ButtonBase>
        </div>
      </Box>
      <DrawerInicio visible={visible} setVisible={setVisible} />
      <DrawerUsuario visible={visibleUsuario} setVisible={setVisibleUsuario} />
      <ModalComponent />
    </Box>
  );
};
