import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import CheckoutForm from './CheckoutForm';
import { useSearchParams } from 'react-router-dom';
import { useUsuario } from '../../../Context/UsuarioContext';
import { getData, updateItem } from '../../../database/firebase';
import moment from 'moment';
import AvatarUsuario from '../../Componentes/AvatarUsuario';
import { useConfig } from '../../../Context/ConfigContext';

const Premium = () => {
  const { activarAlerta } = useConfig();

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [status, setStatus] = useState(null);
  const { usuario, cargarUsuario, chargeUser } = useUsuario();

  const urlParams = new URLSearchParams(window.location.search);
  const encodedUsername = urlParams.get('token');

  const iniciarSesion = async (i) => {
    const data = await getData('usuarios', i);
    chargeUser(data);
  };

  const url = 'https://us-central1-app-bolos.cloudfunctions.net/app';
  // const url = 'http://localhost:5000/app-bolos/us-central1/app';

  const hacerPremium = async (correo, nombre) => {
    await updateItem('usuarios', usuario.nombreUsuario, {
      premium: {
        esPremium: true,
        fechaFin: moment().add(1, 'year').format('YYYY-MM-DD HH:mm:ss'),
        correo: correo,
        nombre: nombre,
      },
    });
    cargarUsuario(usuario.nombreUsuario);
    activarAlerta({
      estado: true,
      status: 'success',
      titulo: 'Ya eres premium',
      descripcion: '',
    });
  };

  const comprobarPremium = (userData) => {
    if (userData.premium && userData.premium.esPremium) {
      const fechaFin = moment(userData.premium.fechaFin);
      const ahora = moment();

      if (fechaFin.isBefore(ahora)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      sessionId &&
      usuario &&
      usuario.nombreUsuario !== 'Anónimo' &&
      !comprobarPremium(usuario)
    ) {
      fetch(`${url}/session-status?session_id=${sessionId}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'complete') {
            setStatus('✅ Pago exitoso');
            hacerPremium(data.customer_email, data.customer_name);
          } else {
            setStatus('❌ Error en el pago');
          }
        })
        .catch(() => setStatus('❌ No se pudo verificar el pago'));
    }
  }, [sessionId, usuario]);

  useEffect(() => {
    if (encodedUsername) {
      const decodedUsername = atob(encodedUsername);
      if (usuario && usuario.nombreUsuario !== decodedUsername) {
        iniciarSesion(decodedUsername);
      }
    }
  }, [encodedUsername, usuario]);

  const [comprar, setComprar] = useState(false);

  const ventajas = {
    Juegos: [
      'Sin anuncios tras las partidas',
      'Partidas ilimitadas en El Corro',
      'Selecciona al capitán de tu equipo en Bolwenger sin ver anuncios',
    ],
    Notificaciones: [
      'Ilimitadas',
      'Chico a chico de un partido',
      'Cambio de bolera',
      'Ganadores de concursos y campeonatos',
      'Noticias (Próximamente)',
      'Nuevo concurso al que te puedas apuntar (Próximamente)',
    ],
    ['Estadísticas']: [
      'Estadísticas de la liga de DH y femenina',
      'Estadísticas de las peñas de DH y femenina',
      'En los partidos de DH y femenina podrás ver diferentes estadisticas de cada peña',
    ],
    ['Redes sociales']: ['Genera imagenes personalizadas de partidos'],
    Sobres: ['Los sobres diarios aumentan de 3 a 5'],
    Peña: [
      'Si perteneces a una peña podrás disfrutar de las siguientes ventajas:',
      'Añadir la publicidad de la peña (Próximamente)',
      'Generar documento con los gastos en kilometraje de la peña (Próximamente)',
      'Listado de socios',
      'Rifa en los partidos (hacer el sorteo y anunciarla) (Próximamente)',
    ],
    Novedades: ['Acceso anticipado a nuevas funcionalidades y juegos'],
    Entrenamiento: [
      'Estadísticas de tus entrenamientos',
      'Listado de bolas con peso, volumen...',
    ],
    Web: ['Sin anuncios'],
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h3' fontWeight='bold' sx={{ mt: 4, mb: 4 }}>
        Mejora tu experiencia con{' '}
        <span style={{ color: '#1DB954' }}>Premium</span>
      </Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: 12,
        }}
      >
        {status ? (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 12,
            }}
          >
            <Typography
              variant='h3'
              fontWeight='bold'
              style={{ marginBottom: 24 }}
            >
              {status}
            </Typography>
            {status === '✅ Pago exitoso' ? (
              <>
                {comprobarPremium(usuario) && (
                  <AvatarUsuario usuario={usuario} premium size={100} />
                )}
                <Typography
                  variant='h6'
                  fontWeight='bold'
                  style={{ marginTop: 24 }}
                >
                  Ya eres premium
                </Typography>
                <Typography
                  variant='h6'
                  fontWeight='bold'
                  style={{ marginTop: 24 }}
                >
                  Reinicia la aplicación para ver los cambios
                </Typography>
              </>
            ) : (
              <Button
                variant='contained'
                sx={{
                  mt: 2,
                  backgroundColor: '#fff',
                  color: '#1DB954',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  setStatus(null);
                  setComprar(true);
                }}
              >
                Suscribirse a Premium
              </Button>
            )}
          </Box>
        ) : comprar ? (
          <CheckoutForm />
        ) : (
          <Grid
            container
            spacing={4}
            justifyContent='center'
            style={{ width: '90%' }}
          >
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  border: '1px solid #333',
                  borderRadius: 3,
                }}
              >
                <CardContent>
                  <Typography variant='h5' fontWeight='bold'>
                    Usuario Normal
                  </Typography>
                  <Typography variant='h6' color='gray'>
                    Gratis
                  </Typography>
                  <Box
                    style={{
                      listStyle: 'none',
                      padding: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: 12,
                    }}
                  >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Check color='success' /> Acceso básico
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Close color='error' /> Sin beneficios extra
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Close color='error' /> Sin recompensas exclusivas
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  backgroundColor: '#1DB954',
                  color: '#fff',
                  borderRadius: 3,
                  flex: 1,
                }}
              >
                <CardContent>
                  <Typography variant='h5' fontWeight='bold' fontSize={16}>
                    Premium
                  </Typography>
                  <Typography variant='h6'>Solo 4,99€ al año</Typography>
                  <Typography variant='h8'>(0,41€ al mes)</Typography>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: 12,
                      flex: 1,
                      overflowY: 'scroll',
                      maxHeight: '50vh',
                    }}
                  >
                    {Object.keys(ventajas).map((key) => (
                      <Box style={{ marginBottom: 12 }}>
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            color: '#4A3B3B',
                          }}
                        >
                          {key}
                        </Typography>
                        {ventajas[key].map((ventaja, index) => (
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Check
                              color='success'
                              style={{ height: 20, width: 20 }}
                            />
                            <Typography
                              key={index}
                              style={{
                                fontWeight: '600',
                                textAlign: 'center',
                                fontSize: 12,
                              }}
                            >
                              {ventaja}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 12,
                    }}
                  >
                    {comprobarPremium(usuario) ? (
                      <AvatarUsuario usuario={usuario} premium size={100} />
                    ) : (
                      <Button
                        variant='contained'
                        sx={{
                          mt: 2,
                          backgroundColor: '#fff',
                          color: '#1DB954',
                          fontWeight: 'bold',
                        }}
                        onClick={() => {
                          if (usuario && usuario.nombreUsuario === 'Anónimo') {
                            activarAlerta({
                              estado: true,
                              status: 'warning',
                              titulo: 'Inicia sesión',
                              descripcion: '',
                            });
                          } else {
                            setComprar(true);
                          }
                        }}
                      >
                        Suscribirse a Premium
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default Premium;
