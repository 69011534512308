import React, { useMemo } from 'react';
import IconoPeña from './IconoPena.js';
import IconoJugador from './IconoJugador.js';
import requireTv from '../../database/requireTv.js';
import PersonIcon from '@material-ui/icons/Person';
import { Avatar } from '@material-ui/core';
import { useData } from '../../Context/DataContext.js';
import { Box } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

const AvatarUsuario = ({ usuario, size = 40, scale = 1, premium = false }) => {
  const { jugadores, buscarPeña } = useData();

  const jugador = useMemo(
    () =>
      jugadores &&
      jugadores.find(
        (item) =>
          item.usuario !== undefined && item.usuario === usuario.nombreUsuario
      ) !== undefined
        ? jugadores.find(
            (item) =>
              item.usuario !== undefined &&
              item.usuario === usuario.nombreUsuario
          )
        : undefined,
    [jugadores, usuario]
  );

  const peña = useMemo(
    () =>
      usuario.favorito && usuario.favorito !== ''
        ? buscarPeña(usuario.favorito)
        : undefined,
    [usuario]
  );

  return (
    <Box
      style={{
        ...(premium && {
          borderWidth: 4,
          borderColor: '#D4AF37',
          borderRadius: 60,
        }),
        position: 'relative',
        width: size,
        height: size,
      }}
    >
      {jugador ? (
        <IconoJugador
          jugador={jugador.id}
          peña={jugador.peña}
          size={size}
          style={{ zIndex: -1, paddingLeft: 3, paddingRight: 2 }}
          paddingHorizontal={0}
          disabled
          scale={scale}
        />
      ) : usuario.medio &&
        usuario.permisos &&
        usuario.permisos.includes('Prensa') ? (
        <img
          style={{
            width: (size - 6) * scale,
            height: (size - 6) * scale,
            borderRadius: 60,
            borderColor: 'black',
            backgroundColor: 'transparent',
          }}
          src={requireTv(usuario.medio)}
        />
      ) : peña ? (
        <IconoPeña
          peñaId={usuario.favorito}
          size={(size + 4) * scale}
          disabled
        />
      ) : (
        <Avatar
          style={{
            height: size * scale,
            width: size * scale,
          }}
        >
          <PersonIcon />
        </Avatar>
      )}
      {premium && (
        <Box
          style={{
            position: 'absolute',
            zIndex: 10000,
            bottom: -size * 0.2,
            right: -size * 0.2,
          }}
        >
          <VerifiedIcon
            color={'#D4AF37'}
            style={{ fontSize: size / 2, color: '#D4AF37' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AvatarUsuario;
