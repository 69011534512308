import React from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { useTema } from '../../Context/TemaContext.js';

export default function Tienda({ url, setVisible }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const { tema } = useTema();

  function redirectToMobileApp() {
    window.location.href = url;

    setTimeout(() => {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);
    }, 1500);
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'Calc(100vh - 70px)',
        outline: 'none',
        boxShadow: 'none',
      }}
    >
      <img
        src={require('../../assets/icono.png')}
        style={{
          height: '25vh',
          width: '25vh',
          marginBottom: 20,
          borderRadius: 24,
        }}
        alt='LOGO'
      />
      <Typography
        style={{
          fontSize: '3vw',
          fontWeight: '600',
          color: tema.colors[500],
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          width: '100%',
        }}
      >
        RESULTADOS DE BOLOS
      </Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'center',
          marginBottom: 24,
          width: '100%',
          marginTop: 24,
        }}
      >
        <a
          href='https://apps.apple.com/es/app/resultados-de-bolos/id1610548244'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            height: '75px',
            textDecoration: 'none',
            width: '48%',
          }}
        >
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              alignItems: 'center',
              backgroundColor: 'black',
              borderRadius: 12,
            }}
          >
            <img
              src={require('../../assets/appStore.png')}
              style={{
                height: '50px',
                width: '50px',
                marginLeft: 7,
              }}
              alt='AppStore'
            />
            <Box
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '3vw',
                marginRight: 7,
              }}
            >
              <Typography
                style={{
                  fontWeight: '400',
                  fontSize: isMobile ? '3vw' : '1vw',
                  color: 'white',
                }}
              >
                Disponible en
              </Typography>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: isMobile ? '3vw' : '1.5vw',
                  color: 'white',
                }}
              >
                App Store
              </Typography>
            </Box>
          </Paper>
        </a>
        <a
          href={`https://play.google.com/store/apps/details?id=com.saro.marcos&hl=es_PY&gl=US`}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            height: '75px',
            textDecoration: 'none',
            width: '48%',
          }}
        >
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              alignItems: 'center',
              backgroundColor: 'black',
              borderRadius: 12,
            }}
          >
            <img
              src={require('../../assets/googlePlay.png')}
              style={{
                height: '50px',
                width: '50px',
                marginLeft: 7,
              }}
              alt='AppStore'
            />
            <Box
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '3vw',
                marginRight: 7,
              }}
            >
              <Typography
                style={{
                  fontWeight: '400',
                  fontSize: isMobile ? '3vw' : '1vw',
                  color: 'white',
                }}
              >
                Disponible en
              </Typography>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: isMobile ? '3vw' : '1.5vw',
                  color: 'white',
                }}
              >
                Google Play
              </Typography>
            </Box>
          </Paper>
        </a>
      </Box>
      {url && (
        <Button
          variant='contained'
          onClick={() => redirectToMobileApp()}
          style={{
            fontSize: isMobile ? '5vw' : '2vw',
            fontWeight: '600',
            borderRadius: 60,
            backgroundColor: tema.colors[500],
            marginTop: 24,
            color: '#fff',
          }}
        >
          Ir a la app
        </Button>
      )}
    </Box>
  );
}
