import React, { useMemo, useState } from 'react';
import { Box, Divider, useMediaQuery } from '@material-ui/core';
import { useUsuario } from '../../Context/UsuarioContext.js';
import { useTheme } from '@mui/material/styles';
import { useData } from '../../Context/DataContext.js';
import { useTema } from '../../Context/TemaContext.js';
import Texto from '../Componentes/Texto.js';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CuadroDeTexto } from '../Componentes/CuadroDeTexto.js';
import IconoJugador from '../Componentes/IconoJugador.js';
import { RFPercentage } from '../../utils/Functions.js';

export const Jugadores = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  // const [loading, setLoading] = useState(true);

  const { jugadores, peñas } = useData();
  const { usuario } = useUsuario();
  const { tema } = useTema();
  const [jugadoresFilter, setJugadoresFilter] = useState(jugadores);
  const [visible, setVisible] = useState(false);

  const [peña, setPeña] = useState([]);
  const [categoria, setCategoria] = useState([]);

  const categorias = [
    'Primera',
    'Segunda',
    'Tercera',
    'Femenino',
    'Veteranos',
    'Aficionados',
    'Categorías Menores',
    'Juvenil',
    'Social',
  ];

  const [search, setSearch] = useState('');

  const updateSearch = (text) => {
    const array = [];
    jugadores.filter((item) => {
      const n = item.nombre
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const p = item.peña
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      if (
        n.includes(
          text
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
      ) {
        array.push(item);
      } else if (
        p.includes(
          text
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
      ) {
        array.push(item);
      }
    });
    setJugadoresFilter(array);
    setSearch(text);
  };

  const data = useMemo(() => {
    return jugadoresFilter
      ? jugadoresFilter.filter((item) => {
          let bool = true;
          if (peña.length > 0 && !peña.includes(item.peña)) {
            bool = false;
          }
          if (categoria.length > 0 && !categoria.includes(item.categoría)) {
            bool = false;
          }
          return bool;
        })
      : [];
  }, [jugadoresFilter, categoria, peña]);

  const Row = ({ index, style }) => {
    const item = data[index];

    if (item) {
      return (
        <div
          style={style}
          key={item.id}
          // onPress={() => {
          //   navigation.navigate("Peña", {
          //     id: item.id,
          //     clasico: clasico && clasico === "Si",
          //   });
          // }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              minHeight: 80,
              alignItems: 'center',
            }}
          >
            <Box style={{ paddingLeft: 10 }}>
              <Box style={{ paddingBottom: 5, paddingTop: 5 }}>
                <IconoJugador jugador={item.id} size={'mediano'} />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <Texto
                style={{ fontSize: RFPercentage(2), fontWeight: '500' }}
                numberOfLines={1}
              >
                {item.nombre}
              </Texto>
              <Texto
                style={{
                  fontSize: RFPercentage(1.4),
                  fontWeight: '300',
                }}
                numberOfLines={1}
              >
                {item.categoría}
              </Texto>
            </Box>
          </Box>
          <Divider />
        </div>
      );
    } else {
      return <div style={{ flex: 8, ...style }} key={Math.random()}></div>;
    }
  };

  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        style={{
          paddingTop: 12,
          marginBottom: 12,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box style={{ width: '95%' }}>
          <CuadroDeTexto
            label='Buscar...'
            variant='outlined'
            value={search}
            onChange={updateSearch}
            fullWidth
            debounceable
          />
        </Box>
      </Box>
      <Box
        style={{
          flex: '1 1 auto',
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={data.length}
              itemSize={80}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};
