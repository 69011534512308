import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { TextField } from '@mui/material';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { UsuarioContext } from '../../Context/UsuarioContext';
// import QRCode from 'react-qr-code';
import { v4 as uuidv4 } from 'uuid';
import {
  createItem,
  createItemWithId,
  deleteItem,
  getRef,
} from '../../database/firebase';
import { onSnapshot } from 'firebase/firestore';
import Texto from '../Componentes/Texto';
import { RFPercentage } from '../../utils/Functions';
import { QRCode } from 'react-qrcode-logo';

export default function Login() {
  const classes = useStyles();

  const { getUser, getUserQR } = useContext(UsuarioContext);

  const [nombreUsuario, setNombreUsuario] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [visible, setVisible] = useState(false);
  const [qrCode, setQrCode] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const comprobarQR = async () => {
    const code = await localStorage.getItem('qrCode');

    if (code) {
      setQrCode(code);
    } else {
      const randomCode = uuidv4();
      await localStorage.setItem('qrCode', randomCode);
      await createItemWithId('qrLogin', randomCode, {
        codigo: randomCode,
        nombreUsuario: null,
      });
      setQrCode(randomCode);
    }
  };

  // useEffect(() => {
  //   comprobarQR();
  // }, []);

  const suscribeCode = async () => {
    const qrRef = await getRef('qrLogin', [], qrCode);

    const unsub = onSnapshot(qrRef, async (docsSnap) => {
      const qrLogin = docsSnap.data();
      if (qrLogin && qrLogin.nombreUsuario) {
        await getUserQR(qrLogin.nombreUsuario);
        await deleteItem('qrLogin', qrCode);
        await localStorage.removeItem('qrCode');
      }
    });
    return unsub;
  };

  useEffect(() => {
    if (qrCode) {
      suscribeCode();
    }
  }, [qrCode]);

  return (
    <Box
      width={1}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        padding: 24,
      }}
    >
      <Box
        style={{
          width: '100%',
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 3,
          }}
        >
          <Texto
            style={{
              textAlign: 'center',
              fontWeight: '600',
              fontSize: RFPercentage(3),
            }}
          >
            Iniciar sesión
          </Texto>
          <Box
            style={{
              paddingTop: RFPercentage(2),
              width: '80%',
              maxWidth: '500px',
            }}
          >
            <TextField
              label='Nombre de usuario'
              variant='outlined'
              value={nombreUsuario}
              onChange={(event) =>
                setNombreUsuario(event.target.value ? event.target.value : '')
              }
              fullWidth
            />
          </Box>
          <Box
            style={{
              paddingTop: RFPercentage(2),
              width: '80%',
              maxWidth: '500px',
            }}
          >
            <TextField
              label='Contraseña'
              type={visible ? 'text' : 'password'}
              value={contraseña}
              variant='outlined'
              onChange={(event) =>
                setContraseña(event.target.value ? event.target.value : '')
              }
              fullWidth
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setVisible((prevState) => !prevState)}
                  >
                    {visible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingTop: RFPercentage(2),
              width: '80%',
              justifyContent: 'space-between',
              maxWidth: '500px',
            }}
          >
            <Button
              className={classes.button}
              variant='contained'
              onClick={() => {
                getUser(nombreUsuario, contraseña);
              }}
            >
              Iniciar sesión
            </Button>
          </Box>
        </Box>
        {/* <Box
          style={{
            flex: 2,
            borderTop: '2px solid #CCC',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '90%',
            flexDirection: 'column',
            marginTop: RFPercentage(2),
          }}
        >
          {qrCode && (
            <QRCode
              value={`https://resultadosdebolos.com/qrLogin/${qrCode}`}
              logoImage={require('../../assets/icono.png')}
              size={RFPercentage(26)}
              logoWidth={RFPercentage(6)}
              removeQrCodeBehindLogo={false}
            />
          )}
          <Texto style={{ marginTop: 12, textAlign: 'center' }}>
            Escanee este código QR para iniciar sesión
          </Texto>
        </Box> */}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  button: {
    height: '48px',
    width: '100%',
    backgroundColor: '#33db7a',
    color: '#FFFFFF',
    borderRadius: '0.4rem',
    fontWeight: 650,
    boxShadow: '0 4px 10px -10px #000 !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: '#FFFFFF',
    },
  },
  icon: {
    borderRadius: 3,
    width: 20,
    height: 20,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: 'transparent',
    },
  },
  root: {
    width: '100%',
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 12,
  },
  checkedIcon: {
    borderRadius: 3,
    backgroundColor: 'orange',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: 'orange',
    },
  },
  tooltipText: {
    fontSize: 14,
    maxWidth: '550px',
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 24,
    paddingLeft: 24,
    borderRadius: 12,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));
