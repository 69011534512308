import { Box } from '@material-ui/core';
import Texto from './Texto';
import { RFPercentage } from '../../utils/Functions';
import { useData } from '../../Context/DataContext';

const ImagenBolera = (props) => {
  const { boleras } = useData();

  const { borderRadius = 14 } = props;

  const bolera =
    boleras !== null && boleras !== undefined && props.bolera !== undefined
      ? boleras.find(
          (item) => item.nombre === props.bolera || item.id === props.bolera
        )
      : undefined;

  if (bolera !== undefined) {
    return (
      <Box
        onClick={props.onPress}
        style={{
          ...props.style,
          position: 'relative',
          borderRadius: borderRadius,
        }}
      >
        {bolera !== undefined && props.nombreVisible && (
          <Box
            style={{
              position: 'absolute',
              width: '100%',
              zIndex: 1000,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              opacity: 0.7,
              paddingVertical: 5,
              bottom: 0,
              left: 0,
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
            }}
          >
            <Texto
              style={{
                textAlign: 'center',
                fontSize: RFPercentage(2),
                fontWeight: 'bold',
                flexWrap: 'nowrap',
                color: 'black',
                maxWidth: '95%',
                alignSelf: 'center',
              }}
            >
              {bolera.nombre}
            </Texto>
          </Box>
        )}
        <img
          alt={bolera.nombre}
          style={{
            ...props.style,
            borderRadius: borderRadius,
            objectFit: 'cover',
          }}
          src={
            bolera.imagen
              ? `${bolera.imagen}?refreshKey=${bolera.lastUpdate}`
              : ''
          }
          onClick={props.onPress}
        />
      </Box>
    );
  } else {
    return (
      <Box onClick={props.onPress} style={props.style}>
        <Box
          style={{ ...props.style, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        />
      </Box>
    );
  }
};

export default ImagenBolera;
