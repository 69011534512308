import React from 'react';
import { useTema } from '../../../../../Context/TemaContext.js';
import { useData } from '../../../../../Context/DataContext.js';
import { RFPercentage, openGps } from '../../../../../utils/Functions.js';
import { useConfig } from '../../../../../Context/ConfigContext.js';
import { Box, Grid } from '@mui/material';
import Texto from '../../../../Componentes/Texto.js';
import ImagenBolera from '../../../../Componentes/ImagenBolera.js';
import IconoJugador from '../../../../Componentes/IconoJugador.js';

const InformacionConcurso = ({ fechas, concurso }) => {
  const { tema } = useTema();
  const { buscarBolera, buscarJugador } = useData();
  const { activarAlerta } = useConfig();

  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'column',
        marginTop: 12,
        alignItems: 'center',
      }}
    >
      <Grid container>
        {concurso.bolera && concurso.bolera !== '' && (
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                height: RFPercentage(40),
                maxWidth: RFPercentage(70),
                width: RFPercentage(70),
                borderRadius: '12px',
              }}
            >
              <ImagenBolera
                style={{
                  height: RFPercentage(40),
                  width: RFPercentage(70),
                  borderRadius: 12,
                }}
                bolera={concurso.bolera ? buscarBolera(concurso.bolera).id : ''}
                onPress={() => {
                  const b = buscarBolera(concurso.bolera);
                  if (b && b.latlng.latitude > 1) {
                    openGps([b.latlng.latitude, b.latlng.longitude], b.nombre);
                  } else {
                    activarAlerta({
                      estado: true,
                      status: 'warning',
                      titulo:
                        'Esta bolera no dispone de ubicación. Se puede añadir editandola desde el apartado de boleras',
                    });
                  }
                }}
                nombreVisible={true}
              />
            </Box>
          </Grid>
        )}
        {concurso.bolera2 && (
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                height: RFPercentage(40),
                maxWidth: RFPercentage(70),
                width: RFPercentage(70),
                borderRadius: '12px',
              }}
            >
              <ImagenBolera
                style={{
                  height: RFPercentage(40),
                  width: RFPercentage(70),
                  borderRadius: 12,
                }}
                bolera={
                  concurso.bolera2 ? buscarBolera(concurso.bolera2).id : ''
                }
                onPress={() => {
                  const b = buscarBolera(concurso.bolera2);
                  if (b && b.latlng.latitude > 1) {
                    openGps([b.latlng.latitude, b.latlng.longitude], b.nombre);
                  } else {
                    activarAlerta({
                      estado: true,
                      status: 'warning',
                      titulo:
                        'Esta bolera no dispone de ubicación. Se puede añadir editandola desde el apartado de boleras',
                    });
                  }
                }}
                nombreVisible={true}
              />
            </Box>
          </Grid>
        )}
        {concurso.record && (
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 2,
                height: RFPercentage(40),
                maxWidth: RFPercentage(70),
                width: RFPercentage(70),
                borderRadius: '12px',
              }}
            >
              <IconoJugador
                jugador={concurso.jugadorRecord}
                size={'grande'}
                nombre
              />
              <Texto>{buscarJugador(concurso.jugadorRecord)?.nombre}</Texto>
              <Texto
                style={{
                  fontWeight: '500',
                  marginTop: 12,
                  fontSize: RFPercentage(2),
                }}
              >
                Record {concurso.record}
              </Texto>
            </Box>
          </Grid>
        )}
        {!['Campeonato Regional', 'Campeonato de España'].includes(
          concurso.tipo
        ) && (
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            style={{
              height: RFPercentage(40),
              maxWidth: RFPercentage(70),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 2,
                height: RFPercentage(40),
                maxWidth: RFPercentage(70),
                width: RFPercentage(70),
                borderRadius: '12px',
              }}
            >
              {concurso.fechaFinal && (
                <Texto
                  style={{
                    fontWeight: '500',
                    marginTop: 12,
                    fontSize: RFPercentage(2),
                  }}
                >{`Fecha de la final: ${concurso.fechaFinal}`}</Texto>
              )}
              {concurso.tipo === 'Final' && (
                <Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: 20,
                    }}
                  >
                    <Texto style={{ fontSize: 20, fontWeight: '700' }}>
                      Se arrastran:
                    </Texto>
                    <Texto
                      style={{
                        fontSize: 20,
                        fontWeight: '400',
                        paddingLeft: 10,
                      }}
                    >
                      {concurso.arrastran}
                    </Texto>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: 20,
                    }}
                  >
                    <Texto style={{ fontSize: 20, fontWeight: '700' }}>
                      Hay semifinales:
                    </Texto>
                    <Texto
                      style={{
                        fontSize: 20,
                        fontWeight: '400',
                        paddingLeft: 10,
                      }}
                    >
                      {concurso.haySemifinales === true ? 'Si' : 'No'}
                    </Texto>
                  </Box>
                  {concurso.haySemifinales && (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: 20,
                      }}
                    >
                      <Texto style={{ fontSize: 20, fontWeight: '700' }}>
                        Tipo de semifinal:
                      </Texto>
                      <Texto
                        style={{
                          fontSize: 20,
                          fontWeight: '400',
                          paddingLeft: 10,
                        }}
                      >
                        {concurso.semifinal}
                      </Texto>
                    </Box>
                  )}
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: 20,
                    }}
                  >
                    <Texto style={{ fontSize: 20, fontWeight: '700' }}>
                      Tipo de final:
                    </Texto>
                    <Texto
                      style={{
                        fontSize: 20,
                        fontWeight: '400',
                        paddingLeft: 10,
                      }}
                    >
                      {concurso.tipoFinal}
                    </Texto>
                  </Box>
                </Box>
              )}
              <Box
                style={{
                  width: '90%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignSelf: 'center',
                  marginTop: 24,
                }}
              >
                {concurso.telefono && (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 6,
                    }}
                  >
                    <Box
                      style={{
                        borderRadius: 60,
                        backgroundColor: 'green',
                        height: 60,
                        width: 60,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {/* <IconButton
                name='phone-in-talk'
                type='material-community'
                size={36}
                color='white'
              /> */}
                    </Box>
                    <Texto
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'blue',
                        textDecorationLine: 'underline',
                        paddingTop: 5,
                      }}
                    >
                      {concurso.telefono}
                    </Texto>
                  </Box>
                )}
                {concurso.telefonos &&
                  concurso.telefonos.map((telefono, index) => (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 6,
                      }}
                    >
                      <Box
                        style={{
                          borderRadius: 60,
                          backgroundColor: 'green',
                          height: 60,
                          width: 60,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {/* <IconButton
                  name='phone-in-talk'
                  type='material-community'
                  size={36}
                  color='white'
                /> */}
                      </Box>

                      <Texto
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          color: 'blue',
                          textDecorationLine: 'underline',
                          paddingTop: 5,
                        }}
                      >
                        {telefono}
                      </Texto>
                    </Box>
                  ))}
              </Box>
              {concurso.horarioTelefono && concurso.horarioTelefono !== '' && (
                <Texto style={{ paddingTop: 15 }}>
                  Horario de llamadas: {concurso.horarioTelefono}
                </Texto>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
      {/* {fechas && (
        <Box style={{ width: '90%', alignSelf: 'center' }}>
          <Calendar
            current={moment(concurso.fecha).format('YYYY-MM-DD')}
            initialDate={moment(concurso.fecha).format('YYYY-MM-DD')}
            markedDates={fechas}
            monthFormat={'MMMM yyyy'}
            onMonthChange={(month) => {}}
            firstDay={1}
            style={{
              width: '100%',
            }}
            theme={tema.calendarTheme}
            hideArrows={concurso.tipo === 'Final'}
          />
        </Box>
      )} */}
    </Box>
  );
};

export default InformacionConcurso;
