import React, { useEffect, useState } from 'react';
import 'moment/locale/es';
import { ordenRondas, RFPercentage } from '../../../../../utils/Functions.js';
import { useTema } from '../../../../../Context/TemaContext.js';
import { useData } from '../../../../../Context/DataContext.js';
import { Box } from '@material-ui/core';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import Texto from '../../../../Componentes/Texto.js';
import Ronda from '../../../../Componentes/Ronda.js';
import IconoJugador from '../../../../Componentes/IconoJugador.js';

const ClasificacionFinal = ({ tiradas, concurso }) => {
  const { buscarJugador } = useData();
  const { tema } = useTema();

  const [clasificacion, setClasificacion] = useState([]);
  const [juegoLibre, setJuegoLibre] = useState(false);

  const [loading, setLoading] = useState(true);

  const cargarClasificacion = async () => {
    const jugadoresArray = [];

    tiradas.forEach((data) => {
      if (data.tipo !== 'Desempate') {
        if (
          data.jugador1 !== undefined &&
          !jugadoresArray.includes(data.jugador1)
        ) {
          jugadoresArray.push(data.jugador1);
        }
        if (
          data.jugador2 !== undefined &&
          !jugadoresArray.includes(data.jugador2)
        ) {
          jugadoresArray.push(data.jugador2);
        }
        if (
          data.jugadores1 !== undefined &&
          !jugadoresArray.includes(
            `${data.jugadores1[0]} - ${data.jugadores1[1]}`
          )
        ) {
          jugadoresArray.push(`${data.jugadores1[0]} - ${data.jugadores1[1]}`);
        }
        if (
          data.jugadores2 !== undefined &&
          !jugadoresArray.includes(
            `${data.jugadores2[0]} - ${data.jugadores2[1]}`
          )
        ) {
          jugadoresArray.push(`${data.jugadores2[0]} - ${data.jugadores2[1]}`);
        }
      }
    });

    if (concurso.tipoFinal !== 'KO') {
      const clasificacionArray = [];
      let juegoLibreEstado = false;

      jugadoresArray.forEach((jugador) => {
        const tiradasJugador = [];
        tiradas.forEach((tirada) => {
          if (
            tirada.jugador1 !== undefined &&
            (tirada.jugador1 === jugador || tirada.jugador2 === jugador)
          ) {
            tiradasJugador.push(tirada);
          } else if (
            tirada.jugadores1 !== undefined &&
            (`${tirada.jugadores1[0]} - ${tirada.jugadores1[1]}` === jugador ||
              `${tirada.jugadores2[0]} - ${tirada.jugadores2[1]}` === jugador)
          ) {
            tiradasJugador.push(tirada);
          }
        });
        let total = 0;
        let octavos = 0;
        let cuartos = 0;
        let semifinales = 0;
        let final = 0;

        tiradasJugador.forEach((tirada) => {
          if (tirada.jugador1 === jugador) {
            if (tirada.chicos === undefined) {
              const bolos = tirada.bolos1 === 'NP' ? 0 : tirada.bolos1;
              total = total + bolos;
            } else {
              juegoLibreEstado = true;
            }
            if (tirada.tipo === 'Octavos') {
              if (tirada.chicos !== undefined) {
                octavos = tirada.chicos1;
              } else {
                octavos = tirada.bolos1;
              }
            }
            if (tirada.tipo === 'Cuartos') {
              if (tirada.chicos !== undefined) {
                cuartos = tirada.chicos1;
              } else {
                cuartos = tirada.bolos1;
              }
            }
            if (tirada.tipo === 'Semifinales') {
              if (tirada.chicos !== undefined) {
                semifinales = tirada.chicos1;
              } else {
                semifinales = tirada.bolos1;
              }
            }
            if (tirada.tipo === 'Final') {
              if (tirada.chicos !== undefined) {
                final = tirada.chicos1;
              } else {
                final = tirada.bolos1;
              }
            }
          } else if (tirada.jugador2 === jugador) {
            if (tirada.chicos === undefined) {
              const bolos = tirada.bolos2 === 'NP' ? 0 : tirada.bolos2;
              total = total + bolos;
            } else {
              juegoLibreEstado = true;
            }
            if (tirada.tipo === 'Octavos') {
              if (tirada.chicos !== undefined) {
                octavos = tirada.chicos2;
              } else {
                octavos = tirada.bolos2;
              }
            }
            if (tirada.tipo === 'Cuartos') {
              if (tirada.chicos !== undefined) {
                cuartos = tirada.chicos2;
              } else {
                cuartos = tirada.bolos2;
              }
            }
            if (tirada.tipo === 'Semifinales') {
              if (tirada.chicos !== undefined) {
                semifinales = tirada.chicos2;
              } else {
                semifinales = tirada.bolos2;
              }
            }
            if (tirada.tipo === 'Final') {
              if (tirada.chicos !== undefined) {
                final = tirada.chicos2;
              } else {
                final = tirada.bolos2;
              }
            }
          } else if (
            `${tirada.jugadores1[0]} - ${tirada.jugadores1[1]}` === jugador
          ) {
            if (tirada.chicos === undefined) {
              const bolos = tirada.bolos1 === 'NP' ? 0 : tirada.bolos1;
              total = total + bolos;
            } else {
              juegoLibreEstado = true;
            }
            if (tirada.tipo === 'Octavos') {
              if (tirada.chicos !== undefined) {
                octavos = tirada.chicos1;
              } else {
                octavos = tirada.bolos1;
              }
            }
            if (tirada.tipo === 'Cuartos') {
              if (tirada.chicos !== undefined) {
                cuartos = tirada.chicos1;
              } else {
                cuartos = tirada.bolos1;
              }
            }
            if (tirada.tipo === 'Semifinales') {
              if (tirada.chicos !== undefined) {
                semifinales = tirada.chicos1;
              } else {
                semifinales = tirada.bolos1;
              }
            }
            if (tirada.tipo === 'Final') {
              if (tirada.chicos !== undefined) {
                final = tirada.chicos1;
              } else {
                final = tirada.bolos1;
              }
            }
          } else if (
            `${tirada.jugadores2[0]} - ${tirada.jugadores2[1]}` === jugador
          ) {
            if (tirada.chicos === undefined) {
              const bolos = tirada.bolos2 === 'NP' ? 0 : tirada.bolos2;
              total = total + bolos;
            } else {
              juegoLibreEstado = true;
            }
            if (tirada.tipo === 'Octavos') {
              if (tirada.chicos !== undefined) {
                octavos = tirada.chicos2;
              } else {
                octavos = tirada.bolos2;
              }
            }
            if (tirada.tipo === 'Cuartos') {
              if (tirada.chicos !== undefined) {
                cuartos = tirada.chicos2;
              } else {
                cuartos = tirada.bolos2;
              }
            }
            if (tirada.tipo === 'Semifinales') {
              if (tirada.chicos !== undefined) {
                semifinales = tirada.chicos2;
              } else {
                semifinales = tirada.bolos2;
              }
            }
            if (tirada.tipo === 'Final') {
              if (tirada.chicos !== undefined) {
                final = tirada.chicos2;
              } else {
                final = tirada.bolos2;
              }
            }
          }
        });
        if (jugador !== '') {
          clasificacionArray.push({
            jugador: jugador,
            total: total,
            octavos: octavos,
            cuartos: cuartos,
            semifinales: semifinales,
            final: final,
          });
        }
      });

      if (
        concurso.arrastran === 'No se arrastran' ||
        juegoLibreEstado === true
      ) {
        clasificacionArray.sort(function (a, b) {
          if (a.octavos === 'NP') {
            return 1;
          } else if (b.octavos === 'NP') {
            return -1;
          } else if (a.octavos < b.octavos) {
            return 1;
          } else if (a.octavos > b.octavos) {
            return -1;
          } else {
            return 0;
          }
        });
        clasificacionArray.sort(function (a, b) {
          if (a.cuartos === 'NP') {
            return 1;
          } else if (b.cuartos === 'NP') {
            return -1;
          } else if (a.cuartos < b.cuartos) {
            return 1;
          } else if (a.cuartos > b.cuartos) {
            return -1;
          } else {
            return 0;
          }
        });
        clasificacionArray.sort(function (a, b) {
          if (a.semifinales === 'NP') {
            return 1;
          } else if (b.semifinales === 'NP') {
            return -1;
          } else if (a.semifinales < b.semifinales) {
            return 1;
          } else if (a.semifinales > b.semifinales) {
            return -1;
          } else {
            return 0;
          }
        });
        clasificacionArray.sort(function (a, b) {
          if (a.final === 'NP') {
            return 1;
          } else if (b.final === 'NP') {
            return -1;
          } else if (a.final < b.final) {
            return 1;
          } else if (a.final > b.final) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (concurso.arrastran === 'Todas menos la final') {
        clasificacionArray.sort(function (a, b) {
          if (a.total < b.total) {
            return 1;
          }
          if (a.total > b.total) {
            return -1;
          }
        });
        clasificacionArray.sort(function (a, b) {
          if (a.final === 'NP') {
            return 1;
          } else if (b.final === 'NP') {
            return -1;
          } else if (a.final < b.final) {
            return 1;
          } else if (a.final > b.final) {
            return -1;
          } else {
            return 0;
          }
        });
      } else {
        clasificacionArray.sort(function (a, b) {
          if (a.total < b.total) {
            return 1;
          }
          if (a.total > b.total) {
            return -1;
          }
        });
      }

      setClasificacion(clasificacionArray);
      setJuegoLibre(juegoLibreEstado);
    }
    setLoading(false);
  };

  useEffect(() => {
    cargarClasificacion();
  }, []);

  return (
    <Box style={{ flex: 1, backgroundColor: tema.backgroundColor }}>
      {/* {loading && <Loader />} */}
      {concurso.tipoFinal !== 'KO' ? (
        <Box>
          {clasificacion.map((item, index) => (
            <Box
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 10,
                borderBottom: `1px solid ${tema.button.default.borderColor}`,
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  width: 25,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Texto numberOfLines={1}>{index + 1}</Texto>
              </Box>
              {concurso.individual ? (
                <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <IconoJugador
                    route={'Competiciones'}
                    jugador={item.jugador}
                    size={'pequeño'}
                  />
                  <Texto
                    numberOfLines={2}
                    style={{ maxWidth: '70%', marginLeft: 5 }}
                  >
                    {buscarJugador(item.jugador)?.nombre}
                  </Texto>
                </Box>
              ) : (
                <Box style={{ flex: 1 }}>
                  {item.jugador.split(' - ')?.map((j, index) => (
                    <Box
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: index === 0 ? 4 : 0,
                      }}
                    >
                      <IconoJugador
                        route={'Competiciones'}
                        jugador={j}
                        size={'enano'}
                      />
                      <Texto
                        numberOfLines={2}
                        style={{
                          maxWidth: '70%',
                          marginLeft: 5,
                          fontSize: RFPercentage(1.6),
                        }}
                      >
                        {buscarJugador(j)?.nombre}
                      </Texto>
                    </Box>
                  ))}
                </Box>
              )}
              {concurso.nJugadores > 15 && (
                <Box
                  style={{
                    minWidth: RFPercentage(10),
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Texto
                    style={{
                      textAlign: 'center',
                      fontSize: RFPercentage(2),
                    }}
                  >
                    {item.octavos === 0 ? '' : item.octavos}
                  </Texto>
                </Box>
              )}
              {concurso.nJugadores > 5 && (
                <Box
                  style={{
                    minWidth: RFPercentage(10),
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Texto
                    style={{
                      textAlign: 'center',
                      fontSize: RFPercentage(2),
                    }}
                  >
                    {item.cuartos === 0 ? '' : item.cuartos}
                  </Texto>
                </Box>
              )}
              {concurso.haySemifinales === true && (
                <Box
                  style={{
                    minWidth: RFPercentage(10),
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Texto
                    style={{
                      textAlign: 'center',
                      fontSize: RFPercentage(2),
                    }}
                  >
                    {item.semifinales === 0 && juegoLibre === false
                      ? ''
                      : item.semifinales}
                  </Texto>
                </Box>
              )}
              <Box
                style={{
                  minWidth: RFPercentage(10),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Texto
                  style={{
                    textAlign: 'center',
                    fontSize: RFPercentage(2),
                  }}
                >
                  {item.final === 0 && juegoLibre === false ? '' : item.final}
                </Texto>
              </Box>
              {juegoLibre === false && (
                <Box
                  style={{ width: 60, display: 'flex', alignItems: 'center' }}
                >
                  <Texto
                    style={{
                      textAlign: 'center',
                      fontSize: RFPercentage(2),
                      fontWeight: 'bold',
                    }}
                  >
                    {item.total}
                  </Texto>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <Box style={{ flex: 1, backgroundColor: tema.backgroundColor }}>
          <TransformWrapper limitToBounds={false} maxScale={2} minScale={0.5}>
            <TransformComponent>
              <Box
                style={{
                  flex: 1,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                >
                  {concurso.cuadro
                    .sort(function (a, b) {
                      if (
                        ordenRondas.indexOf(a.ronda) >
                        ordenRondas.indexOf(b.ronda)
                      ) {
                        return 1;
                      }
                      if (
                        ordenRondas.indexOf(a.ronda) <
                        ordenRondas.indexOf(b.ronda)
                      ) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((item, index) => (
                      <Ronda
                        key={index}
                        ronda={item}
                        tiradas={tiradas}
                        nRondas={concurso.cuadro.length}
                        concurso={concurso}
                        habilitado={false}
                      />
                    ))}
                </Box>
              </Box>
            </TransformComponent>
          </TransformWrapper>
        </Box>
      )}
    </Box>
  );
};

export default ClasificacionFinal;
