import React, { useCallback, useState } from 'react';
import { Box, ButtonBase, Paper } from '@material-ui/core';
import { useData } from '../../../Context/DataContext.js';
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  Map,
} from '@vis.gl/react-google-maps';
import requireModalidad from '../../../database/requireModalidad.js';
import ImagenBolera from '../../Componentes/ImagenBolera.js';
import Texto from '../../Componentes/Texto.js';
import { useTema } from '../../../Context/TemaContext.js';
import { RFPercentage } from '../../../utils/Functions.js';
import CloseIcon from '@mui/icons-material/Close';
import IconoPeña from '../../Componentes/IconoPena.js';

const apiKey = process.env.REACT_APP_TEST_VARIABLE;

export const MapaBoleras = () => {
  const { boleras, buscarBolera, peñas } = useData();
  const { tema } = useTema();

  const Z_INDEX_SELECTED = boleras.length;

  const [selectedId, setSelectedId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const onMarkerClick = useCallback(
    (id, marker) => {
      setSelectedId(id);
    },
    [selectedId]
  );

  const onMapClick = useCallback(() => {
    setSelectedId(null);
  }, []);

  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {selectedId && (
        <Box
          sx={{
            position: 'absolute',
            top: 12,
            left: 12,
            width: '20vw',
            zIndex: 10000000,
            transformOrigin: 'top left',
            transition: 'transform 0.3s ease',
            transform: isHovered ? 'scale(1.2)' : 'scale(0.8)',
            maxHeight: '80vh',
            overflowY: 'auto',
            overflow: 'hidden',
          }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onClick={() => {
            setIsHovered(!isHovered);
          }}
        >
          <Paper
            style={{
              padding: 12,
              backgroundColor: tema.backgroundColor,
              borderRadius: 12,
            }}
            elevation={5}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Texto
                style={{
                  fontSize: RFPercentage(2.6),
                  fontWeight: 'bold',
                  flex: 1,
                }}
              >
                {buscarBolera(selectedId).nombre}
              </Texto>
              <ButtonBase onClick={() => setSelectedId(null)}>
                <CloseIcon style={{ color: tema.texto }} />
              </ButtonBase>
            </Box>
            <ImagenBolera
              bolera={selectedId}
              style={{
                width: '100%',
                height: '10vw',
                marginTop: 12,
                marginBottom: 12,
              }}
            />
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mt={4}
            >
              <Texto
                style={{
                  fontSize: RFPercentage(2),
                  fontWeight: 'bold',
                }}
              >
                Modalidad:
              </Texto>
              <Texto
                style={{
                  fontSize: RFPercentage(2),
                  flex: 1,
                  marginLeft: 6,
                }}
              >
                {buscarBolera(selectedId).modalidad}
              </Texto>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mt={2}
            >
              <Texto
                style={{
                  fontSize: RFPercentage(2),
                  fontWeight: 'bold',
                }}
              >
                Localidad:
              </Texto>
              <Texto
                style={{
                  fontSize: RFPercentage(2),
                  flex: 1,
                  marginLeft: 6,
                }}
              >
                {buscarBolera(selectedId).localidad || ''}
              </Texto>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mt={2}
            >
              {buscarBolera(selectedId).enUso && (
                <Texto
                  style={{
                    fontSize: RFPercentage(2),
                    fontWeight: 'bold',
                  }}
                >
                  En uso
                </Texto>
              )}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              mt={2}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              {peñas
                .filter(
                  (i) =>
                    i.bolera === selectedId ||
                    (i.boleraAuxiliar && i.boleraAuxiliar === selectedId)
                )
                .map((peña) => (
                  <Box key={peña.id} style={{ marginBottom: 4 }}>
                    <IconoPeña peñaId={peña.id} style={{ margin: 4 }} />
                    <Texto
                      numberOfLines={2}
                      style={{
                        maxWidth: 60,
                        fontSize: RFPercentage(1),
                        textAlign: 'center',
                      }}
                    >
                      {peña.nombre}
                    </Texto>
                  </Box>
                ))}
              <Box width={60} style={{ margin: 4 }}></Box>
            </Box>
          </Paper>
        </Box>
      )}
      {apiKey && (
        <Map
          mapId={'bf51a910020fa25a'}
          style={{ width: '100%', height: '100%' }}
          defaultCenter={{ lat: 43.351492, lng: -3.89463 }}
          defaultZoom={10}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        >
          {boleras.map((bolera, index) => {
            let zIndex = index;

            if (selectedId === bolera.id) {
              zIndex = Z_INDEX_SELECTED;
            }

            return (
              <AdvancedMarker
                onClick={(marker) => onMarkerClick(bolera.id, marker)}
                key={bolera.id}
                className='custom-marker'
                style={{
                  transformOrigin:
                    AdvancedMarkerAnchorPoint['BOTTOM'].join(' '),
                }}
                position={{
                  lat: bolera.latlng.latitude,
                  lng: bolera.latlng.longitude,
                }}
              >
                <img
                  src={requireModalidad(bolera.modalidad)}
                  width={32}
                  height={32}
                />
              </AdvancedMarker>
            );
          })}
        </Map>
      )}
    </Box>
  );
};
