import React, { useMemo } from 'react';
import 'moment/locale/es';
import moment from 'moment';
import { useTema } from '../../../../../Context/TemaContext.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Box } from '@mui/material';
import Texto from '../../../../Componentes/Texto.js';
import ConcursoComponente from '../../../../Componentes/ConcursoComponente.js';

const TiradasConcurso = ({ tiradas, concurso }) => {
  const { tema } = useTema();

  const data = useMemo(
    () =>
      tiradas.sort((a, b) => {
        if (a.fecha > b.fecha) {
          return 1;
        } else if (a.fecha < b.fecha) {
          return -1;
        } else {
          if (a.hora > b.hora) {
            return 1;
          }
          if (a.hora < b.hora) {
            return -1;
          }
          return 0;
        }
      }),
    [tiradas]
  );

  const renderItem = ({ item, index }) => {
    let mostrarFecha = false;

    if (data[index - 1] === undefined || data[index - 1].fecha !== item.fecha) {
      mostrarFecha = true;
    }

    return (
      <Box key={item.id}>
        {mostrarFecha && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingVertical: 5,
              paddingTop: 20,
            }}
          >
            <Texto
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              {moment(item.fecha, 'YYYY-MM-DD').format('dddd DD') +
                ' de ' +
                moment(item.fecha, 'YYYY-MM-DD').format('MMMM')}
            </Texto>
          </Box>
        )}
        <ConcursoComponente
          tirada={item}
          concurso={concurso}
          route={'Competiciones'}
        />
      </Box>
    );
  };

  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {tiradas
        .sort((a, b) => {
          if (a.fecha > b.fecha) {
            return 1;
          } else if (a.fecha < b.fecha) {
            return -1;
          } else {
            if (a.hora > b.hora) {
              return 1;
            }
            if (a.hora < b.hora) {
              return -1;
            }
            return 0;
          }
        })
        .map((tirada, index) => renderItem({ item: tirada, index: index }))}
    </Box>
  );
};

export default TiradasConcurso;
