import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { getRef } from '../../../../database/firebase';
import { useData } from '../../../../Context/DataContext';
import { useLocation } from 'react-router-dom';
import { onSnapshot } from 'firebase/firestore';
import PartidoComponente from '../../../Componentes/PartidoComponente';
import Clasificacion from '../../../Componentes/Clasificacion';
import AutoSizer from 'react-virtualized-auto-sizer';
import Texto from '../../../Componentes/Texto';
import { RFPercentage } from '../../../../utils/Functions';
import { useTema } from '../../../../Context/TemaContext';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Autocomplete } from '@mui/material';
import { theme } from '../../../../theme';

const calculateScale = (componentHeight) => {
  const windowHeight = window.innerHeight;
  return windowHeight / componentHeight;
};

export default function Copas() {
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const { tema } = useTema();

  const location = useLocation();
  const { modalidad, copaId } = location.state || {};

  const { copas, buscarCopa } = useData();

  const [partidos, setPartidos] = useState([]);
  const [copa, setCopa] = useState(
    copaId
      ? copaId
      : copas
          .filter((l) => l.modalidad === modalidad && l.año === moment().year())
          .sort((a, b) =>
            a.orden > b.orden ? 1 : a.orden < b.orden ? -1 : 0
          )[0]?.id
  );
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [componentHeight, setComponentHeight] = useState(1000);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const suscribePartidos = async () => {
    const partidosRef = await getRef('partidos', [
      {
        type: 'where',
        param: 'liga',
        condition: '==',
        value: copa,
      },
    ]);

    const unsub = onSnapshot(partidosRef, (docsSnap) => {
      const partidosArray = [];

      docsSnap.forEach((doc) => {
        const data = doc.data();
        partidosArray.push({ ...data, id: doc.id });
      });

      setPartidos(partidosArray);
      setLoading(false);
    });
    return unsub;
  };

  useEffect(() => {
    suscribePartidos();
  }, [copa]);

  useEffect(() => {
    const newScale = calculateScale(componentHeight);
    setScale(newScale > 1.6 ? 1.6 : newScale);

    const handleResize = () => {
      const newScale = calculateScale(componentHeight);
      setScale(newScale > 1.6 ? 1.6 : newScale);
    };

    window.addEventListener('resize', handleResize);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [componentHeight]);

  const onHandleChange = (event, value) => {
    if (value) {
      setLoading(true);
      setCopa(value.id);
    }
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        ...(isMobile && {
          paddingLeft: 12,
          paddingRight: 12,
        }),
      }}
    >
      <Helmet>
        <title>{`Resultados de Bolos | Copas | ${modalidad}`}</title>
      </Helmet>
      <Paper
        style={{
          zIndex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: tema.backgroundColor,
          borderRadius: 12,
          overflow: 'hidden',
          paddingLeft: 12,
          paddingRight: 12,
          marginBottom: 12,
          marginTop: 12,
        }}
      >
        <Autocomplete
          id='copas'
          options={copas
            .filter(
              (l) => l.modalidad === modalidad && l.año === moment().year()
            )
            .sort((a, b) => {
              if (a.orden > b.orden) {
                return 1;
              }
              if (a.orden < b.orden) {
                return -1;
              } else {
                return 0;
              }
            })}
          getOptionLabel={(option) => option.nombre}
          sx={{ width: RFPercentage(60) }}
          renderInput={(params) => <TextField {...params} label='Copas' />}
          value={buscarCopa(copa)}
          onChange={onHandleChange}
          sx={{
            maxWidth: '50%',
            minWidth: '30%',
            '& .MuiInputBase-root': {
              color: tema.texto,
            },
            '& .MuiAutocomplete-popupIndicator': {
              color: tema.texto,
            },
            '& .MuiIconButton-root': {
              color: tema.texto,
            },
          }}
          renderOption={(params, option) => {
            return (
              <li {...params} key={option.id}>
                <Texto
                  style={{
                    fontWeight: '400',
                    fontSize: RFPercentage(1.8),
                    marginLeft: 12,
                    color: 'black',
                  }}
                >
                  {option.nombre}
                </Texto>
              </li>
            );
          }}
        />
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label='Partidos' style={{ color: tema.texto }} />
          <Tab label='Cuadro' style={{ color: tema.texto }} />
        </Tabs>
      </Paper>
      <Paper
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'flex-start',
          backgroundColor: tema.backgroundColor,
          borderRadius: 12,
          overflow: 'hidden',
          marginBottom: 12,
          padding: 12,
        }}
      >
        {tab === 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <Box
                style={{
                  height: height,
                  maxHeight: height,
                  width: width,
                  overflowY: 'auto',
                }}
              >
                <Box>
                  {partidos.filter((item) => item.jornada === 'Fase previa')
                    .length > 0 && (
                    <Texto
                      style={{
                        fontWeight: '600',
                        textAlign: 'center',
                        fontSize: 18,
                        paddingVertical: 5,
                        marginTop: 20,
                      }}
                    >
                      Fase Previa
                    </Texto>
                  )}
                  {partidos
                    .filter((item) => item.jornada === 'Fase previa')
                    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                    .map((partido, index) => (
                      <PartidoComponente
                        key={index}
                        partido={partido}
                        scale={scale}
                      />
                    ))}
                </Box>

                <Box>
                  {partidos.filter((item) => item.jornada === 'Dieciseisavos')
                    .length > 0 && (
                    <Texto
                      style={{
                        fontWeight: '600',
                        textAlign: 'center',
                        fontSize: 18,
                        paddingVertical: 5,
                        marginTop: 20,
                      }}
                    >
                      Dieciseisavos
                    </Texto>
                  )}
                  {partidos
                    .filter((item) => item.jornada === 'Dieciseisavos')
                    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                    .map((partido, index) => (
                      <PartidoComponente
                        key={index}
                        partido={partido}
                        scale={scale}
                      />
                    ))}
                </Box>

                <Box>
                  {partidos.filter((item) => item.jornada === 'Octavos')
                    .length > 0 && (
                    <Texto
                      style={{
                        fontWeight: '600',
                        textAlign: 'center',
                        fontSize: 18,
                        paddingVertical: 5,
                        marginTop: 20,
                      }}
                    >
                      Octavos
                    </Texto>
                  )}
                  {partidos
                    .filter((item) => item.jornada === 'Octavos')
                    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                    .map((partido, index) => (
                      <PartidoComponente
                        key={index}
                        partido={partido}
                        scale={scale}
                      />
                    ))}
                </Box>

                <Box>
                  {partidos.filter((item) => item.jornada === 'Cuartos')
                    .length > 0 && (
                    <Texto
                      style={{
                        fontWeight: '600',
                        textAlign: 'center',
                        fontSize: 18,
                        paddingVertical: 5,
                        marginTop: 20,
                      }}
                    >
                      Cuartos
                    </Texto>
                  )}
                  {partidos
                    .filter((item) => item.jornada === 'Cuartos')
                    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                    .map((partido, index) => (
                      <PartidoComponente
                        key={index}
                        partido={partido}
                        scale={scale}
                      />
                    ))}
                </Box>

                <Box>
                  {partidos.filter((item) => item.jornada === 'Semifinales')
                    .length > 0 && (
                    <Texto
                      style={{
                        fontWeight: '600',
                        textAlign: 'center',
                        fontSize: 18,
                        paddingVertical: 5,
                        marginTop: 20,
                      }}
                    >
                      Semifinales
                    </Texto>
                  )}
                  {partidos
                    .filter((item) => item.jornada === 'Semifinales')
                    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                    .map((partido, index) => (
                      <PartidoComponente
                        key={index}
                        partido={partido}
                        scale={scale}
                      />
                    ))}
                </Box>

                <Box>
                  {partidos.filter((item) => item.jornada === 'Final').length >
                    0 && (
                    <Texto
                      style={{
                        fontWeight: '600',
                        textAlign: 'center',
                        fontSize: 18,
                        paddingVertical: 5,
                        marginTop: 20,
                      }}
                    >
                      Final
                    </Texto>
                  )}
                  {partidos
                    .filter((item) => item.jornada === 'Final')
                    .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                    .map((partido, index) => (
                      <PartidoComponente
                        key={index}
                        partido={partido}
                        scale={scale}
                      />
                    ))}
                </Box>
              </Box>
            )}
          </AutoSizer>
        ) : (
          <Clasificacion
            cargandoClasificacion={loading}
            ligaId={copa}
            partidos={partidos}
          />
        )}
      </Paper>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0.4rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      transform: 'scale(1.05)',
      '& .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.contrastText,
      },
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: window.innerHeight - 150,
  },
  buttonDisabled: {
    borderRadius: '0.8rem',
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon: {
    '& svg': {
      fontSize: 150,
      height: '100%',
    },
  },
  title: {
    fontWeight: 300,
    fontSize: 18,
  },
}));
