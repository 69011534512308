import React from 'react';

const Juego = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <iframe
        src='/game/index.html'
        width='100%'
        height='100%'
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default Juego;
