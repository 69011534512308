import React, { useState } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import { useData } from '../../../Context/DataContext.js';
import { useTema } from '../../../Context/TemaContext.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CuadroDeTexto } from '../../Componentes/CuadroDeTexto.js';
import ImagenBolera from '../../Componentes/ImagenBolera.js';
import MapIcon from '@mui/icons-material/Map';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Router/routes.js';

export const Boleras = () => {
  const { boleras } = useData();
  const { tema } = useTema();
  const [bolerasFilter, setBolerasFilter] = useState(boleras);

  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const updateSearch = (text) => {
    const array = [];
    boleras.forEach((item) => {
      const n = item.nombre
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      const l = item.localidad
        ? item.localidad
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        : '';

      if (
        n.includes(
          text
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        ) ||
        l.includes(
          text
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
      ) {
        array.push(item);
      }
    });

    setBolerasFilter(array);
    setSearch(text);
  };

  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        style={{
          paddingTop: 12,
          marginBottom: 12,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box style={{ flex: 1, marginLeft: 12, marginRight: 12 }}>
          <CuadroDeTexto
            label='Buscar...'
            variant='outlined'
            value={search}
            onChange={updateSearch}
            fullWidth
            debounceable
          />
        </Box>
        <ButtonBase>
          <MapIcon
            sx={{
              marginRight: '12px',
              height: '100%',
              width: '30px',
              color: tema.id === 'Claro' ? 'black' : 'white',
            }}
            onClick={() => {
              navigate(ROUTES.boleras);
            }}
          />
        </ButtonBase>
      </Box>
      <Box
        style={{
          flex: '1 1 auto',
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <Box
              style={{
                height: height,
                maxHeight: height,
                width: width,
                overflowY: 'auto',
              }}
            >
              <Box
                style={{
                  flexWrap: 'wrap',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: 12,
                  paddingRight: 12,
                  flex: 1,
                }}
              >
                {bolerasFilter.map((bolera) => (
                  <ImagenBolera
                    key={bolera.id}
                    style={{
                      height: width * 0.35,
                      width: width * 0.45,
                      borderRadius: 12,
                      marginBottom: 24,
                    }}
                    bolera={bolera.id}
                    nombreVisible={true}
                  />
                ))}
              </Box>
            </Box>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};
