import { Box, CircularProgress } from '@material-ui/core';

export const Loader = (props) => {
  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};
