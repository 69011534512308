import React, { useState } from 'react';
import { useConfig } from '../../../Context/ConfigContext';
import { useUsuario } from '../../../Context/UsuarioContext';
import { createItemWithId, updateItem } from '../../../database/firebase';
import moment from 'moment';
import {
  IconButton,
  MenuItem,
  Paper,
  Select,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Button, FormControl, Box } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  RFPercentage,
  modalidades,
  responsiveWidth,
} from '../../../utils/Functions';
import Texto from '../../Componentes/Texto';
import AddIcon from '@mui/icons-material/Add';
import PeñasModal from '../../Componentes/Modals/PenasModal';
import Boton from '../../Componentes/Boton';
import IconoPeña from '../../Componentes/IconoPena';
import { CuadroDeTexto } from '../../Componentes/CuadroDeTexto';

const CrearLiga = () => {
  const { activarAlerta } = useConfig();
  const { usuario } = useUsuario();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const sobres = ['bronce', 'plata', 'oro', 'promesa', 'especial'];

  const initialValues = {
    año: moment().format('YYYY'),
    modalidad: modalidades[0].texto,
    nombre: '',
    organizadores: [usuario.nombreUsuario],
    resultadoFinal: '6 chicos',
    tipo: 'Oficial',
    cromos: sobres[0],
    peñas: [],
  };

  const validationSchema = Yup.object({
    nombre: Yup.string().required('El nombre es obligatorio'),
    peñas: Yup.array().min(2, 'Debe haber al menos dos peñas'),
  });

  const [visible, setVisible] = useState(false);
  const resultadosPosibles = [
    'Mejor de 4',
    'Mejor de 6',
    'Mejor de 10',
    '6 chicos',
    '10 chicos',
  ];

  const crearLiga = async (values) => {
    const clasificacion = values.peñas.map((peña) => {
      return {
        ch_c: 0,
        ch_f: 0,
        dif: 0,
        empatados: 0,
        ganados: 0,
        jugados: 0,
        nombre: peña,
        perdidos: 0,
        puntos: 0,
        ultimosResultados: [],
      };
    });

    const nombreLiga = `${values.nombre} ${moment().format('YYYY')}`;

    await createItemWithId('ligas', nombreLiga, {
      año: parseInt(values.año),
      jornadaActual: 1,
      jornadas:
        values.peñas.length % 2 === 0
          ? (values.peñas.length - 1) * 2
          : values.peñas.length * 2,
      modalidad: values.modalidad,
      orden:
        values.tipo === 'Oficial'
          ? 1
          : values.tipo === 'Categorías Menores'
          ? 20
          : values.tipo === 'Aficionados'
          ? 50
          : 100,
      clasificacion: clasificacion,
      tipo: values.tipo,
      organizadores: values.organizadores,
      resultadoFinal: values.resultadoFinal,
    });

    values.peñas.forEach(async (peña) => {
      await updateItem('peñas', peña, {
        liga: nombreLiga,
        activa: true,
      });
    });

    activarAlerta({
      estado: true,
      status: 'success',
      titulo: 'Liga creada correctamente',
      descripcion: '',
    });
  };

  // const guardarLiga = async () => {
  //   await updateItem('ligas', props.params.liga, {
  //     nombre: nombre,
  //     tipo:
  //       tipo === 0
  //         ? 'Oficial'
  //         : tipo === 1
  //         ? 'Categorías Menores'
  //         : tipo === 2
  //         ? 'Aficionados'
  //         : 'Liga de Invierno',
  //     organizadores: organizadores,
  //     resultadoFinal: resultadosPosibles[resultadoFinal],
  //     cromos: sobres[index],
  //     apuestas: apuestas,
  //     colores: colores,
  //   });

  //   if (partidos.length > 0) {
  //     partidos.forEach(async (partido) => {
  //       await createItem('partidos', {
  //         local: partido.local,
  //         visitante: partido.visitante,
  //         jornada:
  //           partido.jornada !== undefined ? parseInt(partido.jornada) : 1,
  //         fecha: partido.fecha !== undefined ? partido.fecha : '',
  //         hora: partido.hora !== undefined ? partido.hora : '',
  //         estado: 'Sin jugar',
  //         bolera: boleraDefecto
  //           ? boleraDefecto.id
  //           : buscarPeña(partido.local).bolera,
  //         televisiones: [],
  //         foro: [],
  //         log: [],
  //         chicos: [],
  //         tipo: 'liga',
  //         liga: props.params.liga,
  //         ch_l: 0,
  //         ch_v: 0,
  //         apuestas1: 0,
  //         apuestas2: 0,
  //         apuestasX: 0,
  //         segundoPartido: false,
  //         apuestas: apuestas,
  //       });
  //     });
  //   }

  //   activarAlerta({
  //     estado: true,
  //     status: 'success',
  //     titulo: 'Guardando correctamente',
  //     descripcion: '',
  //   });
  // };

  return (
    <Paper style={{ flex: 1, height: '96%' }}>
      <Box
        flex={1}
        padding={2}
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Texto
          style={{
            fontSize: RFPercentage(3),
            marginBottom: 12,
            fontWeight: '600',
          }}
        >
          Crear Liga
        </Texto>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={crearLiga}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                padding={2}
                width={isMobile ? responsiveWidth(90) : responsiveWidth(40)}
              >
                <CuadroDeTexto
                  name='nombre'
                  label='Nombre'
                  value={values.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nombre && Boolean(errors.nombre)}
                  helperText={touched.nombre && errors.nombre}
                  debounceable
                />
                <Box
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 12,
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <FormControl margin='normal' style={{ width: '50%' }}>
                    <Texto
                      style={{ fontWeight: '300', fontSize: RFPercentage(1.2) }}
                    >
                      Modalidad
                    </Texto>
                    <Select
                      labelId='Modalidad'
                      id='Modalidad'
                      value={values.modalidad}
                      style={{ width: '90%' }}
                      onChange={(event) =>
                        setFieldValue('modalidad', event.target.value)
                      }
                    >
                      {modalidades.map((i) => (
                        <MenuItem style={{ width: '100%' }} value={i.texto}>
                          {i.texto}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl margin='normal' style={{ width: '50%' }}>
                    <Texto
                      style={{ fontWeight: '300', fontSize: RFPercentage(1.2) }}
                    >
                      Tipo
                    </Texto>
                    <Select
                      labelId='Tipo'
                      id='Tipo'
                      value={values.tipo}
                      style={{ width: '90%' }}
                      onChange={(event) =>
                        setFieldValue('tipo', event.target.value)
                      }
                    >
                      {[
                        'Oficial',
                        'Categorías Menores',
                        'Aficionados',
                        'Liga de Invierno',
                      ].map((i) => (
                        <MenuItem style={{ width: '100%' }} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 12,
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <FormControl margin='normal' style={{ width: '50%' }}>
                    <Texto
                      style={{ fontWeight: '300', fontSize: RFPercentage(1.2) }}
                    >
                      Resultado Final
                    </Texto>
                    <Select
                      labelId='Resultado Final'
                      id='Resultado Final'
                      value={values.resultadoFinal}
                      style={{ width: '90%' }}
                      onChange={(event) =>
                        setFieldValue('resultadoFinal', event.target.value)
                      }
                    >
                      {resultadosPosibles.map((i) => (
                        <MenuItem style={{ width: '100%' }} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl margin='normal' style={{ width: '50%' }}>
                    <Texto
                      style={{ fontWeight: '300', fontSize: RFPercentage(1.2) }}
                    >
                      Sobres
                    </Texto>
                    <Select
                      labelId='Sobres'
                      placeholder='Sobres'
                      id='Sobres'
                      value={values.cromos}
                      style={{ width: '90%' }}
                      onChange={(event) =>
                        setFieldValue('cromos', event.target.value)
                      }
                    >
                      {sobres.map((i) => (
                        <MenuItem style={{ width: '100%' }} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Texto style={{ marginTop: 24, fontWeight: '500' }}>
                  Añadir Peñas
                </Texto>
                {visible && (
                  <PeñasModal
                    visible={visible}
                    setVisible={setVisible}
                    actualizarPeña={(p) => {
                      setFieldValue('peñas', [...values.peñas, p]);
                    }}
                  />
                )}
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  {values.peñas.map((peña, index) => (
                    <Boton
                      key={index}
                      title={peña.nombre}
                      titleSize={8}
                      Image={() => (
                        <IconoPeña disabled={true} peñaId={peña.id} />
                      )}
                      height={100}
                      width={isMobile ? responsiveWidth(25) : '150px'}
                      numberOfLines={2}
                      style={{ marginBottom: 12 }}
                      onPress={() => {
                        setFieldValue(
                          'peñas',
                          values.peñas.filter((i) => i.id !== peña.id)
                        );
                      }}
                    />
                  ))}
                  <IconButton onClick={() => setVisible(true)}>
                    <AddIcon />
                  </IconButton>
                </Box>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  margin='normal'
                  style={{ marginTop: 24 }}
                  fullWidth
                >
                  Guardar
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};

export default CrearLiga;
