import React, { useRef, useState } from 'react';
import './TVCarousel.css';
import { useData } from '../../../Context/DataContext';
import IconoPeña from '../../Componentes/IconoPena';
import requireTvCard from '../../../database/requireTvCard';
import { RFPercentage } from '../../../utils/Functions';

const TVCarousel = ({ partidos, setVideo }) => {
  const { buscarPeña } = useData();

  const carouselRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollLeft = () => {
    if (carouselRef.current) {
      const newScrollPosition = Math.max(scrollPosition - RFPercentage(42), 0);
      carouselRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
      setScrollPosition(newScrollPosition);
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      const maxScroll =
        carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
      const newScrollPosition = Math.min(
        scrollPosition + RFPercentage(42),
        maxScroll
      );
      carouselRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
      setScrollPosition(newScrollPosition);
    }
  };

  return (
    <div className='carousel-container'>
      {scrollPosition !== 0 && (
        <button className='scroll-button left' onClick={scrollLeft}>
          &lt;
        </button>
      )}
      <div className='tv-carousel' ref={carouselRef}>
        {partidos.map((partido) => (
          <div
            key={partido.id}
            className='tv'
            style={{
              backgroundImage: `url(${requireTvCard(partido.televisión)})`,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
              width: RFPercentage(42),
              height: RFPercentage(22),
            }}
            onClick={() => {
              if (partido.enlace) {
                setVideo({
                  enlace: partido.enlace,
                  titulo: `${buscarPeña(partido.local).nombre} vs ${
                    buscarPeña(partido.visitante).nombre
                  }`,
                });
              } else {
                alert('El partido no tiene enlace');
              }
            }}
          >
            <div className='match'>
              <div className='team'>
                <IconoPeña size={RFPercentage(14)} peñaId={partido.local} />
              </div>
              <p className='team-line' style={{ fontSize: RFPercentage(6) }}>
                vs
              </p>
              <div className='team'>
                <IconoPeña size={RFPercentage(14)} peñaId={partido.visitante} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {(!carouselRef.current ||
        (carouselRef.current &&
          scrollPosition !==
            carouselRef.current.scrollWidth -
              carouselRef.current.clientWidth)) && (
        <button className='scroll-button right' onClick={scrollRight}>
          &gt;
        </button>
      )}
    </div>
  );
};

export default TVCarousel;
