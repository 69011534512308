import { Box } from '@material-ui/core';
import { RFPercentage } from '../../../utils/Functions';
import CollapseComponent from '../../Componentes/CollapseComponent';
import { useTema } from '../../../Context/TemaContext';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../Router/routes';
import { Helmet } from 'react-helmet';

const ModalidadesInfo = () => {
  const { tema } = useTema();

  const location = useLocation();

  return (
    <Box
      style={{
        width: '100%',
        transition: 'background-color 0.5s ease, color 1s ease',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {location && location.pathname === ROUTES.modalidades && (
        <Helmet>
          <title>{`Resultados de Bolos | Modalidades`}</title>
          <meta
            name='description'
            content={`Breve explicación de las diferentes modalidades de bolos que se practícan en Cantabria.`}
          />
        </Helmet>
      )}
      <Box
        sx={{
          width: '80%',
          backgroundColor: tema.backgroundColor,
          borderRadius: 12,
          border: `2px solid ${tema.button.default.borderColor}`,
        }}
      >
        <CollapseComponent
          title={'Bolo Palma'}
          icon={require('../../../assets/modalidades/boloPalma.png')}
        >
          <div
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: RFPercentage(2),
              lineHeight: 1.5,
              paddingLeft: 12,
              paddingRight: 12,
              color: tema.texto,
              maxWidth: '80%',
            }}
          >
            <img
              alt={'Bolo Palma'}
              src={require('../../../assets/modalidades/boloPalmaBanner.jpeg')}
              style={{
                width: '100%',
                height: RFPercentage(30),
                objectFit: 'cover',
                borderRadius: 12,
                marginTop: 12,
              }}
            />
            <p>
              <strong>Bolo Palma</strong> es una modalidad de bolos con una
              fuerte tradición en Cantabria y la zona oriental de Asturias.
              Aunque es más común en estas regiones, emigrantes cántabros y
              asturianos han llevado este juego a lugares como Madrid,
              Barcelona, el País Vasco y hasta a países de América Latina como
              México, Argentina, Chile y Venezuela.
            </p>
            <p>
              Se juega en un <strong>campo rectangular</strong> llamado bolera,
              que mide entre 30 y 34 metros de largo. El objetivo es derribar 9
              bolos con una bola de madera. Además, se usa un bolo más pequeño,
              el <strong>emboque</strong>, que ayuda a determinar la mano a la
              que se juega (efecto de la bola) y su valor. Para obtener el
              emboque hay que dar al primer bolo y que la bola derribe al
              emboque o pase entre éste y el tablón lateral.
            </p>
            <p>
              El juego se divide en dos fases: <strong>tirar</strong> y{' '}
              <strong>birlar</strong>. En la fase de tirar, lanzas las bolas
              desde el tiro para derribar bolos y obtener el emboque. Luego, en
              la fase de birlar, vuelves a lanzar desde la posición donde quedó
              cada bola para seguir acumulando bolos. Cada bolo cuenta como un
              punto, excepto el del medio, que vale dos si lo derribas solo.
            </p>
            <p>
              Existen dos modalidades de juego: en equipo y en concursos. En el
              juego en equipo, como en la liga y copa, participan equipos de
              cuatro jugadores que se alternan en la elección de condiciones del
              juego. En el juego de concurso, utilizado en competiciones
              individuales o en parejas, cada jugador realiza ocho tiradas con
              distintas condiciones, y el resultado se basa en la suma de estas
              tiradas.
            </p>
          </div>
        </CollapseComponent>
      </Box>
      <Box
        sx={{
          width: '80%',
          backgroundColor: tema.backgroundColor,
          borderRadius: 12,
          border: `2px solid ${tema.button.default.borderColor}`,
          marginTop: 12,
        }}
      >
        <CollapseComponent
          title={'Pasabolo Losa'}
          icon={require('../../../assets/modalidades/pasaboloLosa.png')}
        >
          <div
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: RFPercentage(2),
              lineHeight: 1.5,
              maxWidth: 800,
              paddingLeft: 12,
              paddingRight: 12,
              color: tema.texto,
            }}
          >
            <img
              alt={'Pasabolo Losa'}
              src={require('../../../assets/modalidades/pasaboloLosaBanner.jpg')}
              style={{
                width: '100%',
                height: RFPercentage(30),
                objectFit: 'cover',
                borderRadius: 12,
                marginTop: 12,
              }}
            />
            <p>
              La modalidad conocida como <strong>Pasabolo Losa</strong> o{' '}
              <strong>Pasabolo Trasmerano</strong> recibe su nombre por la
              superficie en la que se ubican los bolos, una piedra o losa, y el
              movimiento del brazo al lanzar la bola. También se conoce por la
              región donde se juega: la comarca de Trasmiera, situada entre la
              ría de Cubas y el río Asón. Esta es una de las modalidades más
              antiguas de Cantabria y España, y su estilo de juego, que imita a
              los cazadores prehistóricos, junto con los materiales usados
              (bolos de madera, losa de piedra, barro y agua), sugiere que es un
              juego ancestral.
            </p>
            <p>
              El campo de juego, llamado <strong>bolera</strong>, mide
              aproximadamente 30 metros de largo por 20 de ancho y se divide en
              tres zonas: el pas de tiro, la losa y la raya.
            </p>
            <p>
              Se utilizan 9 bolos, que miden entre 30 y 32 centímetros. Estos
              bolos son ramas de un arbusto local llamado agracio, sin tornear,
              y se colocan en la losa con la ayuda de arcilla, en agujeros
              hechos a propósito.
            </p>
            <p>
              La bola, hecha de encina, es cilíndrica y redondeada para
              adaptarse a la mano. Pesa entre 1,3 y 2 kilogramos y se lanza
              desde un escalón (pas de tiro) situado a 7,5 metros de distancia.
              El objetivo es golpear los bolos para que superen una línea
              semicircular.
            </p>
            <p>
              La <strong>raya</strong> es un semicírculo situado a 12-15 metros
              de la losa que determina el valor de las jugadas de tiro. Los
              bolos que pasen la raya valen 10 puntos, y los que no, 1 punto,
              siempre que la bola haya pasado la raya. El bolo del medio vale 2
              puntos si se derriba solo y 11 puntos si pasa la raya. La fase de
              tiro se completa con el <strong>birle</strong>, que se realiza
              desde un punto fijo situado detrás de la raya y en diagonal a la
              caja de los bolos.
            </p>
            <p>
              Hay dos formas de jugar: <strong>partida</strong> y
              <strong>concurso</strong>. En la partida, generalmente por equipos
              y a diez chicos/juegos, se utiliza en torneos de liga y copa. Es
              crucial empezar bien, ya que el primer jugador en alcanzar 50
              bolos gana el chico. Si fallas en el birle (bola blanca), pierdes
              la mano. En el juego de concurso, ya sea individual o en parejas,
              se realizan ocho tiradas acumulativas, y se utiliza en campeonatos
              y concursos durante las fiestas de los pueblos.
            </p>
          </div>
        </CollapseComponent>
      </Box>
      <Box
        sx={{
          width: '80%',
          backgroundColor: tema.backgroundColor,
          borderRadius: 12,
          border: `2px solid ${tema.button.default.borderColor}`,
          marginTop: 12,
        }}
      >
        <CollapseComponent
          title={'Pasabolo Tablón'}
          icon={require('../../../assets/modalidades/pasaboloTablon.png')}
        >
          <div
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: RFPercentage(2),
              lineHeight: 1.5,
              maxWidth: 800,
              paddingLeft: 12,
              paddingRight: 12,
              color: tema.texto,
            }}
          >
            <img
              alt={'Pasabolo Tablón'}
              src={require('../../../assets/modalidades/pasaboloTablonBanner.jpg')}
              style={{
                width: '100%',
                height: RFPercentage(30),
                objectFit: 'cover',
                borderRadius: 12,
                marginTop: 12,
              }}
            />
            <p>
              El <strong>Pasabolo Tablón</strong> es una modalidad de bolos que
              se juega sobre un tablón de madera, dando nombre a esta
              emocionante variante. Es muy popular en la región de Cantabria, y
              se juega en un campo que tiene un tablón donde se colocan los
              bolos y un área para lanzar la bola.
            </p>
            <p>
              El campo de juego, conocido como <strong>bolera</strong>, es un
              rectángulo de aproximadamente 30 metros de largo por 10 metros de
              ancho. Está dividido en varias zonas clave: el tablón donde se
              colocan los bolos, la línea de tiro desde donde se lanza la bola,
              y el área de birle para las jugadas finales.
            </p>
            <p>
              En este juego se utilizan 9 bolos, hechos generalmente de madera
              de haya o roble. Los bolos se colocan en el tablón y el objetivo
              es derribarlos con una bola de madera. La bola, que tiene un
              diámetro estándar y un peso que varía según el jugador, se lanza
              desde la línea de tiro con el fin de derribar los bolos.
            </p>
            <p>
              El sistema de puntuación es sencillo: los bolos que se derriban
              durante el tiro valen puntos, y la posición de los bolos tras el
              lanzamiento puede influir en la puntuación final. En la fase de
              birle, se realizan lanzamientos adicionales para intentar derribar
              los bolos que no cayeron durante el tiro inicial.
            </p>
            <p>
              Existen dos formas principales de jugar: en partidas por equipos o
              en competiciones individuales. En los torneos por equipos, los
              jugadores se turnan para lanzar y acumular puntos, mientras que en
              las competiciones individuales, los jugadores realizan
              lanzamientos bajo reglas estrictas para sumar la mayor cantidad de
              puntos posible.
            </p>
          </div>
        </CollapseComponent>
      </Box>
      <Box
        sx={{
          width: '80%',
          backgroundColor: tema.backgroundColor,
          borderRadius: 12,
          border: `2px solid ${tema.button.default.borderColor}`,
          marginTop: 12,
        }}
      >
        <CollapseComponent
          title={'Bolo Pasiego'}
          icon={require('../../../assets/modalidades/boloPasiego.png')}
        >
          <div
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: RFPercentage(2),
              lineHeight: 1.5,
              maxWidth: 800,
              paddingLeft: 12,
              paddingRight: 12,
              color: tema.texto,
            }}
          >
            <img
              alt={'Bolo Pasiego'}
              src={require('../../../assets/modalidades/boloPasiegoBanner.jpg')}
              style={{
                width: '100%',
                height: RFPercentage(30),
                objectFit: 'cover',
                borderRadius: 12,
                marginTop: 12,
              }}
            />
            <p>
              Tradicionalmente, los <strong>bolos</strong> se jugaban en las
              fiestas de las villas pasiegas como Vega de Pas, San Pedro del
              Romeral y Resconorio en Luena, cerca del puerto del Escudo. Desde
              que la disciplina se incorporó a la Federación en 2001 y con la
              migración de los pasiegos a zonas industriales, este juego se ha
              extendido a otras localidades de la región como Santander,
              Torrelavega, Piélagos y Muriedas.
            </p>
            <p>
              El <strong>campo de juego</strong>, conocido como bolera, es
              rectangular, mide 26 x 6 metros y se divide en tres áreas: la zona
              de mano (o tiro), la zona de bolos y la zona de birle. El suelo
              puede ser de tierra o de cemento.
            </p>
            <p>
              Se usan 9 bolos, hechos de abedul, avellano o fresno, dispuestos
              en tres filas formando un cuadrado con una separación de 1,4
              metros entre ellos. Los tres bolos de la fila central se colocan
              sobre un tablón de 6 metros de largo y 30 centímetros de ancho.
              También se utiliza un bolo más pequeño llamado{' '}
              <strong>cuatro</strong>, que vale cuatro puntos si se derriba bajo
              ciertas condiciones. El bolo central vale dos puntos si se derriba
              solo.
            </p>
            <p>
              Las bolas, conocidas como <strong>agarraderas</strong> o{' '}
              <strong>llaves</strong>, tienen un peso que varía entre 3 y 6 kg y
              un diámetro máximo de 28 cm. Estas bolas cuentan con una ranura
              para los cuatro dedos y un agujero para el pulgar.
            </p>
            <p>
              Desde la zona de mano (o tiro), las bolas deben golpear el tablón
              para que el lanzamiento sea válido. En la fase de birle, puedes
              apuntar a cualquier fila que tenga tablón (en algunas boleras,
              todas las filas tienen tablón, como en la modalidad de Tres
              Tablones, practicada en pueblos vecinos de Burgos).
            </p>
            <p>
              El objetivo es derribar los bolos y el cuatro (similar al emboque
              en Bolo Palma) para sumar puntos en cada tirada, que consiste en
              dos lanzamientos: mano y birle. En el birle, no se coloca el
              cuatro.
            </p>
            <p>
              Hay dos formas de jugar: <strong>juego libre</strong> y{' '}
              <strong>juego de concurso</strong>. En el juego libre, utilizado
              en enfrentamientos por equipos como en liga y copa, se juegan seis
              chicos de cuarenta bolos. Cada equipo elige las condiciones del
              juego, como la mano, la colocación del cuatro y sus condiciones de
              validez. En el juego de concurso, que se juega individualmente o
              en parejas, cada jugador realiza cuatro tiradas de dos bolas con
              posiciones del cuatro establecidas por el reglamento. El resultado
              se basa en la suma de las cuatro tiradas.
            </p>
          </div>
        </CollapseComponent>
      </Box>
    </Box>
  );
};

export default ModalidadesInfo;
