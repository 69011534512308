import { TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTema } from '../../Context/TemaContext';
import { makeStyles } from '@material-ui/core/styles';

export const CuadroDeTexto = (props) => {
  const { tema } = useTema();

  const useStyles = makeStyles((theme) => ({
    customInput: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: tema.texto,
        },
        '&:hover fieldset': {
          borderColor: tema.colors[300],
        },
        '&.Mui-focused fieldset': {
          borderColor: tema.colors[500],
        },
      },
      width: '100%',
    },
  }));

  const classes = useStyles();
  const [value, setValue] = useState(props.value);

  const handleChangeState = () => {
    return props.onChange(value ?? '');
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (props.debounceable) {
      setValue(inputValue);
    } else {
      props.onChange(inputValue);
    }
  };

  useEffect(() => {
    if (props.debounceable === true && props.value !== value) {
      const timeoutId = setTimeout(() => {
        handleChangeState();
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [value]);

  return (
    <TextField
      className={classes.customInput}
      label={props.label}
      InputLabelProps={{
        shrink: props.labelUp,
        style: {
          color: tema.texto,
        },
      }}
      style={props.style}
      name={props.name}
      value={props.debounceable ? value : props.value}
      onChange={handleChange}
      fullWidth={props.fullWidth ?? false}
      required={props.required ?? false}
      autoFocus={props.autoFocus ?? false}
      variant={props.variant ?? 'outlined'}
      InputProps={{
        readOnly: props.readonly ?? false,
        style: {
          fontSize: props.fontSize ?? '14px',
          borderRadius: 12,
        },
        startAdornment: props.startIcon ?? null,
        endAdornment: props.endIcon ?? null,
      }}
      inputProps={{
        maxLength: props.maxLength,
        style: {
          textAlign: props.textAlign ?? 'left',
          color: tema.texto,
        },
      }}
      disabled={props.disabled}
    />
  );
};
