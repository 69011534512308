import { Box } from '@material-ui/core';
import { useTema } from '../../Context/TemaContext';
import { innerWidth } from '../../utils/Functions';
import Texto from './Texto';
import { Button } from '@mui/material';

const Boton = (props) => {
  const {
    width = innerWidth * 0.9,
    height = 45,
    style = {},
    title = '',
    titleSize = 12,
    titleColor = null,
    IconButton = null,
    iconSize = null,
    iconColor = null,
    Image = null,
    onPress,
    borderRadius = 10,
    disabled = false,
    horizontal = false,
    numberOfLines = null,
    shadow = true,
    onlyread = false,
    borderWidth = 1,
    backgroundColor = null,
  } = props;

  const { tema } = useTema();

  return (
    <Button
      onClick={onPress}
      disabled={onlyread || disabled || onPress === undefined}
    >
      <Box
        style={{
          ...style,
          width: width,
          height: height,
          borderRadius: borderRadius,
          backgroundColor: backgroundColor
            ? backgroundColor
            : disabled
            ? tema.button.disabled.backgroundColor
            : tema.backgroundColor,
          transition:
            'background-color 0.5s ease, color 1s ease, border-color 1s ease',
          display: 'flex',
          flexDirection: horizontal ? 'row' : 'column',
          justifyContent: !(IconButton || Image) ? 'center' : 'space-between',
          alignItems: 'center',
          padding: 5,
          border: `${borderWidth}px solid ${tema.button.default.borderColor}`,
          ...(shadow && {
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.3,
            shadowRadius: 1,
            shadowColor: disabled ? 'transparent' : 'rgb(100, 100, 100)',
            elevation: 1,
          }),
        }}
      >
        {(IconButton || Image) && (
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: height,
            }}
          >
            {IconButton && (
              <IconButton
                style={{
                  color: iconColor ? iconColor : disabled ? 'grey' : tema.texto,
                  height: iconSize ? iconSize : height * 0.6,
                  width: iconSize ? iconSize : height * 0.6,
                }}
              />
            )}
            {Image && <Image />}
          </Box>
        )}
        <Texto
          numberOfLines={
            numberOfLines !== null ? numberOfLines : horizontal ? 2 : 1
          }
          style={{
            display: title === '' ? 'none' : 'flex',
            maxWidth: '80%',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: titleSize,
            color: titleColor ? titleColor : disabled ? 'grey' : tema.texto,
          }}
        >
          {title}
        </Texto>
      </Box>
    </Button>
  );
};

export default Boton;
