import React from 'react';
import { useTema } from '../../../../Context/TemaContext';
import { Box } from '@material-ui/core';
import Texto from '../../../Componentes/Texto';
import requireTv from '../../../../database/requireTv';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PodcastsIcon from '@mui/icons-material/Podcasts';

const Podcast = ({
  item,
  indexSeleccionado,
  setIndexSeleccionado,
  border = false,
}) => {
  const { tema } = useTema();
  return (
    <Box
      key={item.id}
      style={{
        backgroundColor: tema.backgroundColor,
        width: '99%',
        alignSelf: 'center',
        marginBottom: 8,
        marginTop: 4,
        paddingBottom: 4,
        ...(border && {
          borderBottom: `1px solid ${tema.button.default.borderColor}`,
        }),
      }}
    >
      <Box
        style={{
          paddingHorizontal: 5,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 5,
        }}
        onClick={() =>
          setIndexSeleccionado(indexSeleccionado === item.id ? null : item.id)
        }
      >
        {indexSeleccionado === item.id ? (
          <ExpandMoreIcon style={{ color: tema.texto }} />
        ) : (
          <KeyboardArrowRightIcon style={{ color: tema.texto }} />
        )}
        <Texto
          numberOfLines={1}
          style={{
            fontWeight: 'bold',
            maxWidth: '90%',
            flex: 1,
            paddingLeft: 10,
            fontSize: 16,
          }}
        >
          {item.titulo}
        </Texto>
        <PodcastsIcon style={{ color: tema.texto }} />
        {item.medio !== undefined && item.medio !== null && (
          <img
            src={requireTv(item.medio)}
            style={{
              width: 20,
              height: 20,
              borderRadius: 60,
              marginLeft: 6,
              backgroundColor: 'white',
            }}
          />
        )}
      </Box>
      {indexSeleccionado === item.id && (
        <iframe
          src={item.enlace}
          width='100%'
          height='200'
          frameborder='0'
          allowfullscreen=''
          scrolling='no'
          loading='lazy'
        ></iframe>
      )}
    </Box>
  );
};

export default Podcast;
