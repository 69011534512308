import { getFilterData } from '../database/firebase';
import CryptoJS from 'crypto-js';

// Clave secreta para cifrar y descifrar
const secretKey = process.env.REACT_APP_SECRET_KEY;

// Función para encriptar los datos
export const encryptData = (data) => {
  if (!data) {
    console.error('Data is not a valid object');
    return null;
  }
  if (!secretKey) {
    console.error('Secret key is undefined or null');
    return null;
  }
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return Math.abs(hash);
}

// Función para desencriptar los datos
export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const { innerHeight, innerWidth } = window;

export function RFPercentage(percent) {
  const standardLength = innerWidth > innerHeight ? innerHeight : innerWidth;

  if (innerWidth > innerHeight) {
    const deviceHeight = standardLength;

    const heightPercent = (percent * deviceHeight) / 110;
    return Math.round(heightPercent);
  } else {
    const deviceHeight = standardLength;

    const heightPercent = (percent * deviceHeight) / 80;
    return Math.round(heightPercent);
  }
}

export const openGps = (coordenadas, nombre, tipo = 'bolera') => {
  var scheme = 'geo:0,0?q=';
  var latLng = `${coordenadas[0]},${coordenadas[1]}`;
  var url = `${scheme}${latLng}(Bolera ${nombre})`;
  var urlEscuela = `${scheme}${latLng}(Escuela ${nombre})`;
  try {
    // Linking.openURL(tipo === 'bolera' ? url : urlEscuela)
    //   .then(() => null)
    //   .catch(() => null);
  } catch (error) {}
};

export function randomBoolean(probabilityPercentage) {
  if (probabilityPercentage < 0 || probabilityPercentage > 100) {
    throw new Error('El porcentaje debe estar entre 0 y 100');
  }

  const randomValue = Math.random() * 100;
  return randomValue < probabilityPercentage;
}

export async function mandarNotificaciones(titulo, descripcion, type, id) {
  const usuarios = await getFilterData('usuarios', [
    {
      type: 'where',
      param: 'notificaciones',
      condition: 'array-contains',
      value: 'Admin',
    },
  ]);
  const tokens = usuarios.map((i) => i.expoPushToken);

  try {
    fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: tokens,
        sound: 'default',
        title: titulo,
        body: descripcion,
        data: { type: type, id: id },
        _displayInForeground: true,
      }),
    });
  } catch (error) {}
}

export const responsiveWidth = (num) => {
  return innerWidth * (num / 100);
};

export const responsiveHeight = (num) => {
  return innerHeight * (num / 100);
};

export const responsiveScale = (tamaño, porcentaje, dirección = 'width') => {
  const d = dirección === 'height' ? innerHeight : innerWidth;
  return (d * (porcentaje / 100)) / tamaño;
};

export const youtubeParser = (url) => {
  if (url && url !== undefined && url !== '') {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(live\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);

    return match && match[8].length == 11 ? match[8] : '';
  } else {
    return '';
  }
};

export const chico = [
  {
    id: 'Chico 1',
    tiro: '',
    raya: '',
    emboque: '',
    total1: 0,
    total2: 0,
    tirada1: [
      {
        anotaciones: [],
        tiro: 0,
        total: 0,
      },
      {
        anotaciones: [],
        tiro: 0,
        total: 0,
      },
      {
        anotaciones: [],
        tiro: 0,
        total: 0,
      },
    ],
    tirada2: [
      {
        anotaciones: [],
        tiro: 0,
        total: 0,
      },
      {
        anotaciones: [],
        tiro: 0,
        total: 0,
      },
      {
        anotaciones: [],
        tiro: 0,
        total: 0,
      },
    ],
  },
];

export const tirada = [
  {
    anotaciones: [],
    tiro: 0,
    total: 0,
  },
  {
    anotaciones: [],
    tiro: 0,
    total: 0,
  },
  {
    anotaciones: [],
    tiro: 0,
    total: 0,
  },
  {
    anotaciones: [],
    tiro: 0,
    total: 0,
  },
  {
    anotaciones: [],
    tiro: 0,
    total: 0,
  },
  {
    anotaciones: [],
    tiro: 0,
    total: 0,
  },
  {
    anotaciones: [],
    tiro: 0,
    total: 0,
  },
  {
    anotaciones: [],
    tiro: 0,
    total: 0,
  },
];

export const ordenDeLigas = [
  'Rucecan - División de Honor',
  'Bolos en Femenino',
  'Primera',
  'Segunda Especial - Grupo 1',
  'Segunda Especial - Grupo 2',
  'Segunda - Grupo 1',
  'Segunda - Grupo 2',
  'Segunda - Grupo 3',
  'Tercera - Grupo 1',
  'Tercera - Grupo 2',
  'Tercera - Grupo 3',
  'Veteranos - Primera',
  'Veteranos - Segunda',
  'Liga Alevín-Benjamín - Grupo 1',
  'Liga Alevín-Benjamín - Grupo 2',
  'Liga Alevín-Benjamín - Grupo 3',
  'Liga Alevín-Benjamín - Grupo 4',
  'Liga Cadete',
  'Liga Infantil',
  'Liga Femenina Escolar',
  'Liga Asturiana',
  'Liga Valles de Iguña y Anievas',
  'Liga de aficionados de Torrelavega',
  'Liga de bares de Valdáliga - Grupo A',
  'Liga de bares de Valdáliga - Grupo B',
  'XI Liga de Aficionados de Santander La Grúa de Piedra',
];

export const tiposConcurso = [
  'Campeonato de España',
  'Campeonato Regional',
  'Clasificacion',
  'Final',
];

export const categorias = [
  'Primera',
  'Segunda',
  'Tercera',
  'Cuarta',
  'Femenino',
  'Femenino Cadete',
  'Femenino Escolar',
  'Veteranos',
  'Benjamín',
  'Alevín',
  'Infantil',
  'Cadete',
  'Juvenil',
  'Social',
];

export const categoriasConcursos = [
  'Primera',
  'Segunda',
  'Tercera',
  'Cuarta',
  'Femenino',
  'Femenino Cadete',
  'Femenino Escolar',
  'Veteranos',
  'Categorías Menores',
  'Juvenil',
  'Social',
];

export const medios = [
  {
    nombre: 'CANTABRIALIVESTREAM',
    tipo: 'televisión',
    id: 'CANTABRIALIVESTREAM',
  },
  {
    nombre: 'El Carru Leña',
    tipo: 'redes',
    id: 'El Carru Leña',
  },
  {
    nombre: 'Onda Occidental Cantabria',
    tipo: 'televisión',
    id: 'Onda Occidental Cantabria',
  },
  {
    nombre: 'Popular TV',
    tipo: 'televisión',
    id: 'Popular TV',
  },
  {
    nombre: 'Radio Foramontanos',
    tipo: 'radio',
    id: 'Radio Foramontanos',
  },
  {
    nombre: 'Radio Studio',
    tipo: 'radio',
    id: 'Radio Studio',
  },
  {
    nombre: 'Simancas Sport',
    tipo: 'televisión',
    id: 'Simancas Sport',
  },
  {
    nombre: 'SportPublic TV',
    tipo: 'televisión',
    id: 'SportPublic TV',
  },
  {
    nombre: 'ifomo',
    tipo: 'televisión',
    id: 'ifomo',
  },
];

export const ordenRondas = [
  'Dieciseisavos',
  'Octavos',
  'Cuartos',
  'Semifinales',
  'Final',
];

export const modalidades = [
  {
    id: 1,
    texto: 'Bolo Palma',
    imagen: require('../assets/modalidades/boloPalmaBanner.jpeg'),
    icono: require('../assets/modalidades/boloPalma.png'),
  },
  {
    id: 2,
    texto: 'Bolo Pasiego',
    imagen: require('../assets/modalidades/boloPasiegoBanner.jpg'),
    icono: require('../assets/modalidades/boloPasiego.png'),
  },
  {
    id: 3,
    texto: 'Pasabolo Losa',
    imagen: require('../assets/modalidades/pasaboloLosaBanner.jpg'),
    icono: require('../assets/modalidades/pasaboloLosa.png'),
  },
  {
    id: 4,
    texto: 'Pasabolo Tablón',
    imagen: require('../assets/modalidades/pasaboloTablonBanner.jpg'),
    icono: require('../assets/modalidades/pasaboloTablon.png'),
  },
];

export const palabras = [
  'BOLA',
  'BOLO',
  'EMBOQUE',
  'NULA',
  'CABALLO',
  'BOLERA',
  'ARENA',
  'ESTACA',
  'CAJA',
  'MANO',
  'PULGAR',
  'FLEJE',
  'TIRO',
  'BIRLE',
  'QUEDA',
  'BLANCA',
  'CHAPA',
  'CORTA',
  'APEBOL',
  'PEÑA',
  'MORRA',
  'CONEJO',
  'TABLON',
];
