import React from 'react';
import { Box, Drawer, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { TabsInicio } from './TabsInicio.js';
import { useTema } from '../../Context/TemaContext.js';

export const DrawerInicio = ({ visible, setVisible }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const { tema } = useTema();

  return (
    <Drawer anchor={'left'} open={visible} onClose={() => setVisible(false)}>
      <div
        role='presentation'
        style={{
          width: isMobile ? '90vw' : '30vw',
          height: '100vh',
        }}
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        <Box
          style={{
            width: '100%',
            height: '100%',
            borderRadius: 12,
            backgroundColor: tema.backgroundColor,
          }}
        >
          <TabsInicio />
        </Box>
      </div>
    </Drawer>
  );
};
