import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Icon from '@mdi/react';
import { mdiTrophy } from '@mdi/js';
import { mdiTrophyVariant } from '@mdi/js';
import { mdiTournament } from '@mdi/js';
import CrearLiga from '../Formularios/CrearLiga.js';
import Grid from '@mui/material/Grid2';
import { RFPercentage } from '../../../utils/Functions.js';
import CrearArbitro from '../Formularios/CrearArbitro.js';

export default function Admin() {
  const classes = useStyles();

  const [selected, setSelected] = useState(null);

  const Boton = (props) => {
    const { disabled, onClick, icon, label } = props;

    return (
      <Paper
        className={disabled ? classes.buttonDisabled : classes.button}
        style={{ height: '100%', borderRadius: 20 }}
      >
        <Box
          display='flex'
          style={{
            borderRadius: 20,
            height: '100%',
            width: '100%',
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.2)' : 'white',
          }}
        >
          <Button
            disabled={disabled}
            onClick={onClick}
            aria-label={label}
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <Box
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box p={2}>{icon}</Box>
              <Typography className={classes.title} variant='h5'>
                {label}
              </Typography>
            </Box>
          </Button>
        </Box>
      </Paper>
    );
  };

  const botones = [
    {
      label: 'Crear Liga',
      onClick: () => {
        setSelected('Crear Liga');
      },
      icon: <Icon path={mdiTrophy} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Copa',
      onClick: () => setSelected('Crear Copa'),
      icon: <Icon path={mdiTrophyVariant} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Concurso',
      onClick: () => setSelected('Crear Concurso'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Final',
      onClick: () => setSelected('Crear Final'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Campeonato',
      onClick: () => setSelected('Crear Campeonato'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Jugador',
      onClick: () => setSelected('Crear Jugador'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Peña',
      onClick: () => setSelected('Crear Peña'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Bolera',
      onClick: () => setSelected('Crear Bolera'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Noticia',
      onClick: () => setSelected('Crear Noticia'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Árbitro',
      onClick: () => setSelected('Crear Árbitro'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: false,
    },
    {
      label: 'Asignar Árbitros',
      onClick: () => setSelected('Asignar Árbitros'),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: false,
    },
  ];

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        position: 'relative',
      }}
    >
      {!selected && (
        <Box
          py={3}
          width={1}
          height={1}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Grid
            container
            spacing={3}
            justifyContent='center'
            alignItems='center'
            style={{ height: '100%' }}
            role='menu'
          >
            {botones.map((item) => (
              <Grid size={{ xs: 6, md: 4, lg: 3 }} key={item.label} item>
                <Boton {...item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {selected && (
        <Box style={{ position: 'absolute', top: RFPercentage(2), left: 0 }}>
          <IconButton onClick={() => setSelected(null)}>
            <ChevronLeftIcon sx={{ fontSize: RFPercentage(7) }} />
          </IconButton>
        </Box>
      )}
      {selected === 'Crear Liga' && <CrearLiga />}
      {selected === 'Crear Árbitro' && <CrearArbitro />}
      {selected === 'Asignar Árbitros' && <CrearLiga />}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0.4rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'black',
      transform: 'scale(1.05)',
      '& .MuiSvgIcon-colorPrimary': {
        color: 'black',
      },
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  buttonDisabled: {
    borderRadius: '0.8rem',
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  icon: {
    '& svg': {
      fontSize: 150,
      height: '100%',
    },
  },
  title: {
    fontWeight: 300,
    fontSize: 18,
  },
}));
