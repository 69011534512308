import { Box, Modal, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useConfig } from '../../Context/ConfigContext';
import Texto from '../Componentes/Texto';
import CloseIcon from '@mui/icons-material/Close';
import { useTema } from '../../Context/TemaContext';
import { innerHeight, innerWidth } from '../../utils/Functions';

export default function ModalComponent() {
  const { modalVisible, setModalVisible } = useConfig();
  const { tema } = useTema();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10000000000,
        border: 'none',
        outline: 'none',
      }}
      open={modalVisible !== null}
      onClose={() => setModalVisible(null)}
    >
      <Box
        style={{
          height: '90%',
          width: isMobile ? innerWidth * 0.8 : innerWidth * 0.6,
          minWidth: innerWidth * 0.5,
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          display: 'flex',
          flexDirection: 'column',
          backgroundColor:
            modalVisible && modalVisible.backgroundColor
              ? modalVisible.backgroundColor
              : tema.backgroundColor,
        }}
      >
        <Box
          style={{
            height: 40,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: 12,
          }}
        >
          {modalVisible && modalVisible.title !== undefined && (
            <Texto>{modalVisible.title}</Texto>
          )}
          <Box onClick={() => setModalVisible(null)}>
            <CloseIcon
              style={{
                height: innerHeight * 0.03,
                width: innerHeight * 0.03,
                color: tema.texto,
              }}
            />
          </Box>
        </Box>
        {modalVisible && modalVisible.children}
      </Box>
    </Modal>
  );
}
