import { Box } from '@material-ui/core';
import { Divider } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import requireTv from '../../database/requireTv.js';
import Texto from './Texto.js';
import { useData } from '../../Context/DataContext.js';
import IconoPeña from './IconoPena.js';
import { RFPercentage } from '../../utils/Functions.js';
import { useTema } from '../../Context/TemaContext.js';

const PartidoComponente = ({ partido, fecha = false, scale = 1 }) => {
  const { peñas } = useData();
  const { tema } = useTema();

  const local = useMemo(
    () =>
      (peñas &&
        peñas.find(
          (item) => item.id === partido.local || item.nombre === partido.local
        )) || {
        nombre: partido.local,
        id: partido.local,
      },
    [partido.local]
  );

  const visitante = useMemo(
    () =>
      (peñas &&
        peñas.find(
          (item) =>
            item.id === partido.visitante || item.nombre === partido.visitante
        )) || {
        nombre: partido.visitante,
        id: partido.visitante,
      },
    [partido.visitante]
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 6 * scale,
        position: 'relative',
        width: '100%',
        backgroundColor: tema.backgroundColor,
        transition: 'background-color 0.5s ease, color 1s ease',
      }}
      key={partido.id}
    >
      <Box>
        {partido.estado === 'Suspendido' && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Texto
              style={{
                fontSize: RFPercentage(1.6 * scale),
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              SUSPENDIDO
            </Texto>
          </Box>
        )}

        {partido.televisiones.map((item, index) => (
          <img
            key={index}
            src={requireTv(item.televisión)}
            style={{
              width: 25 * scale,
              height: 25 * scale,
              position: 'absolute',
              top: 0,
              right: 1,
            }}
            alt={'television'}
          />
        ))}
      </Box>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 5,
        }}
      >
        <Texto
          numberOfLines={2}
          style={{
            flex: 1,
            textAlign: 'right',
            marginLeft: 5,
            marginRight: 12,
            fontSize: RFPercentage(2.4 * scale),
          }}
        >
          {local.nombre}
        </Texto>
        <IconoPeña peñaId={local.id} size={60 * scale} />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: RFPercentage(3.5 * scale) * 3,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {fecha && (
            <Texto
              numberOfLines={1}
              style={{
                fontSize: RFPercentage(1.2 * scale),
                fontWeight: '200',
              }}
            >
              {moment(partido.fecha, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </Texto>
          )}
          {(moment().format('YYYY-MM-DD') === partido.fecha &&
            moment().format('HH:mm') >= partido.hora) ||
          moment().format('YYYY-MM-DD') > partido.fecha ? (
            <Texto
              numberOfLines={1}
              style={{
                color:
                  partido.estado === 'Terminado' ||
                  partido.estado === 'Suspendido'
                    ? tema.texto
                    : 'rgba(50,180,60,1)',
                fontSize: RFPercentage(4 * scale),
                marginLeft: 12,
                marginRight: 12,
                fontWeight: '500',
              }}
            >
              {`${partido.ch_l} - ${partido.ch_v}`}
            </Texto>
          ) : (
            <Texto
              numberOfLines={1}
              style={{
                fontSize: RFPercentage(4 * scale),
                marginLeft: 12,
                marginRight: 12,
                fontWeight: '500',
              }}
            >{`${partido.hora}`}</Texto>
          )}
        </Box>
        <IconoPeña peñaId={visitante.id} size={60 * scale} />
        <Texto
          numberOfLines={2}
          style={{
            flex: 1,
            textAlign: 'left',
            marginLeft: 12,
            marginRight: 5,
            fontSize: RFPercentage(2.4 * scale),
          }}
        >
          {visitante.nombre}
        </Texto>
      </Box>
      <Divider />
    </Box>
  );
};

export default PartidoComponente;
