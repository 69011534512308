import { useState } from 'react';
import { Avatar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { RFPercentage } from '../../utils/Functions';
import { ROUTES } from '../Router/routes';

export const Logo = ({ openDrawer }) => {
  const [isHovered, setIsHovered] = useState(false);

  const location = useLocation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const IconoApp = () => {
    const size = RFPercentage(10) > 50 ? 50 : RFPercentage(10);
    return (
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'transform 0.3s ease-in-out',
          transform: `scale(1.${isHovered ? '1' : 0})`,
        }}
      >
        <Avatar
          sx={{
            mr: 1,
            height: size,
            width: size,
            backgroundColor: 'transparent',
          }}
        >
          <img
            src={require('../../assets/nuevoLogo.png')}
            style={{
              height: size,
              width: size,
              borderRadius: 12,
            }}
            alt='LOGO'
          />
        </Avatar>
      </Box>
    );
  };

  return (
    <Box>
      {location && location.pathname !== ROUTES.inicio ? (
        <Link to={ROUTES.inicio} style={{ textDecoration: 'none' }}>
          <IconoApp />
        </Link>
      ) : (
        <Box onClick={openDrawer}>
          <IconoApp />
        </Box>
      )}
    </Box>
  );
};
