import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { Grid } from '@mui/material';
import { TabsInicio } from './TabsInicio.js';
import { PartidosInicio } from './PartidosInicio.js';
import { useData } from '../../Context/DataContext.js';
import { Loader } from '../Componentes/Loader.js';
import { useTema } from '../../Context/TemaContext.js';
import { Helmet } from 'react-helmet';

export const Aplicacion = () => {
  const { datosLoading } = useData();
  const { tema } = useTema();

  if (datosLoading) {
    return (
      <Box
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: 12,
      }}
    >
      <Helmet>
        <title>{`Resultados de bolos | Resultados`}</title>
      </Helmet>
      <Grid
        container
        style={{ height: '100%', width: '100%' }}
        spacing={{ xs: 0, md: 2, lg: 2 }}
      >
        <Grid item xs={12} md={7} lg={7}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 12,
            }}
          >
            <PartidosInicio />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          lg={5}
          sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}
        >
          <Paper
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 12,
              backgroundColor: tema.backgroundColor,
              transition: 'background-color 0.5s ease, color 1s ease',
              overflow: 'hidden',
            }}
          >
            <TabsInicio />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
