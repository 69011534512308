import React, { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { Box } from '@mui/material';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe(
  'pk_live_51QpqQ4HbYhiQ6llluYJATSUS5N8RWetx4oRWvvU30K0phUZVtulq49MqQgp2FYow2UMtQgEe7CXY6Nqj7RGI61BO006wuF5CK3'
);

// const stripePromise = loadStripe(
//   'pk_test_51QpqQEH4jr5Aw13RF7xdKKncoCMZ8eNNDZ7LyDKNgYaY9lZrxpIYZ40d3r1achhKr9oCSvUn0DkxHHBDEH6IWJZI00LzoaLran'
// );

const CheckoutForm = () => {
  const fetchClientSecret = useCallback(() => {
    const url = 'https://us-central1-app-bolos.cloudfunctions.net/app';
    // const url = 'http://localhost:5000/app-bolos/us-central1/app';

    return fetch(`${url}/create-checkout-session`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Respuesta del backend:', data);
        if (!data.clientSecret || typeof data.clientSecret !== 'string') {
          throw new Error('El clientSecret no es válido');
        }
        return data.clientSecret;
      })
      .catch(console.error);
  }, []);

  const options = { fetchClientSecret };

  return (
    <Box
      id='checkout'
      style={{
        overflowY: 'auto',
        width: '100%',
        height: '70vh',
      }}
    >
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Box>
  );
};

export default CheckoutForm;

// import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
// import { useState } from 'react';
// import { Button } from '@mui/material';

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [loading, setLoading] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       const response = await fetch(
//         'https://us-central1-app-bolos.cloudfunctions.net/app/create-payment-intent',
//         {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//         }
//       );

//       const data = await response.json();

//       if (!stripe || !elements) return;

//       const { error } = await stripe.confirmCardPayment(data.clientSecret, {
//         payment_method: {
//           card: elements.getElement(CardElement),
//         },
//       });

//       setLoading(false);

//       if (error) {
//         alert('Pago fallido: ' + error.message);
//       } else {
//         alert('Pago exitoso 🎉');
//       }
//     } catch (error) {
//       setLoading(false);
//       alert('Hubo un error procesando el pago.');
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} style={{ textAlign: 'center', width: '50%' }}>
//       <CardElement />
//       <Button
//         type='submit'
//         variant='contained'
//         sx={{
//           mt: 2,
//           backgroundColor: '#fff',
//           color: '#1DB954',
//           fontWeight: 'bold',
//         }}
//         disabled={loading}
//       >
//         {loading ? 'Procesando...' : 'Pagar suscripción'}
//       </Button>
//     </form>
//   );
// };

// export default CheckoutForm;
